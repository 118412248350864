.tickmark {
    width: 52px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .tickmark svg {
    width: 100%;
    height: 100%;
  }
  
  .tickmark-circle {
    stroke: #00b894;
    stroke-width: 2;
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    animation: tickmark-circle-anim 1.5s ease forwards;
  }
  
  @keyframes tickmark-circle-anim {
    to {
      stroke-dashoffset: 0;
    }
  }
  
  .tickmark-check {
    stroke: #00b894;
    stroke-width: 2;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: tickmark-check-anim 0.5s ease forwards 0.8s;
  }
  
  @keyframes tickmark-check-anim {
    to {
      stroke-dashoffset: 0;
    }
  }