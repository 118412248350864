@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap");
/* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_extend.scss */
.flex-center-start {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
}

/* Normal desktop :1200px. */
/* Normal desktop :992px. */
/* Tablet desktop :768px. */
/* small mobile :320px. */
/* Large Mobile :480px. */
/* 1. Theme default css */
/* Normal desktop :1200px. */
/* Normal desktop :992px. */
/* Tablet desktop :768px. */
/* small mobile :320px. */
/* Large Mobile :480px. */
/* line 6, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
body {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-style: normal;
}

/* line 13, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
.img {
  max-width: 100%;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* line 17, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
a,
.button {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* line 21, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
a:focus,
.button:focus, button:focus {
  text-decoration: none;
  outline: none;
}

/* line 26, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
a:focus {
  text-decoration: none;
}

/* line 29, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
a:focus,
a:hover,
.portfolio-cat a:hover,
.footer -menu li a:hover {
  text-decoration: none;
}

/* line 35, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
a,
button {
  color: #1F1F1F;
  outline: medium none;
}

/* line 40, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
h1, h2, h3, h4, h5 {
  font-family: "Roboto", sans-serif;
  color: #191d34;
}

/* line 44, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

/* line 53, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
ul {
  margin: 0px;
  padding: 0px;
}

/* line 57, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
li {
  list-style: none;
}

/* line 60, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
p {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: #7A838B;
  margin-bottom: 13px;
  font-family: "Roboto", sans-serif;
}

/* line 69, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
label {
  color: #7e7e7e;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}

/* line 75, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
*::-moz-selection {
  background: #444;
  color: #fff;
  text-shadow: none;
}

/* line 80, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
::-moz-selection {
  background: #444;
  color: #fff;
  text-shadow: none;
}

/* line 85, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
::selection {
  background: #444;
  color: #fff;
  text-shadow: none;
}

/* line 90, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
*::-webkit-input-placeholder {
  color: #cccccc;
  font-size: 14px;
  opacity: 1;
}

/* line 95, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
*:-ms-input-placeholder {
  color: #cccccc;
  font-size: 14px;
  opacity: 1;
}

/* line 100, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
*::-ms-input-placeholder {
  color: #cccccc;
  font-size: 14px;
  opacity: 1;
}

/* line 105, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
*::placeholder {
  color: #cccccc;
  font-size: 14px;
  opacity: 1;
}

/* line 111, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
h3 {
  font-size: 24px;
}

/* line 115, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
.mb-65 {
  margin-bottom: 67px;
}

/* line 119, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
.black-bg {
  background: #020c26 !important;
}

/* line 123, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
.white-bg {
  background: #ffffff;
}

/* line 126, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
.gray-bg {
  background: #f5f5f5;
}

/* line 142, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
.overlay {
  position: relative;
  z-index: 0;
}

/* line 146, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
.overlay::before {
  position: absolute;
  content: "";
  background-color: #040E27;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: .5;
}

/* line 158, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
.overlay2 {
  position: relative;
  z-index: 0;
}

/* line 162, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
.overlay2::before {
  position: absolute;
  content: "";
  background-color: #191d34;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.6;
}

/* line 174, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
.overlay_03 {
  position: relative;
  z-index: 0;
}

/* line 178, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
.overlay_03::before {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #191d34;
  opacity: .6;
  content: '';
  z-index: -1;
}

/* line 191, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
.bradcam_overlay {
  position: relative;
  z-index: 0;
}

/* line 195, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
.bradcam_overlay::before {
  position: absolute;
  content: "";
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#5db2ff+0,7db9e8+100&1+24,0+96 */
  background: -moz-linear-gradient(left, #5db2ff 0%, #65b4f9 24%, rgba(124, 185, 233, 0) 96%, rgba(125, 185, 232, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #5db2ff 0%, #65b4f9 24%, rgba(124, 185, 233, 0) 96%, rgba(125, 185, 232, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #5db2ff 0%, #65b4f9 24%, rgba(124, 185, 233, 0) 96%, rgba(125, 185, 232, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5db2ff', endColorstr='#007db9e8',GradientType=1 );
  /* IE6-9 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 1;
}

/* line 211, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
.section-padding {
  padding-top: 120px;
  padding-bottom: 120px;
}

/* line 215, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
.pt-120 {
  padding-top: 120px;
}

/* button style */
/* line 221, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
.owl-carousel .owl-nav div {
  background: transparent;
  height: 50px;
  left: 0px;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 50px;
  color: #707070;
  background-color: transparent;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  left: 50px;
  font-size: 15px;
  line-height: 50px;
  border: 1px solid #4D6174;
  left: 150px;
  color: #fff;
}

/* line 249, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
.owl-carousel .owl-nav div.owl-next {
  left: auto;
  right: 150px;
}

/* line 254, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
.owl-carousel .owl-nav div.owl-next i {
  position: relative;
  right: 0;
}

/* line 261, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
.owl-carousel .owl-nav div.owl-prev i {
  position: relative;
  top: 0px;
}

/* line 271, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
.owl-carousel:hover .owl-nav div {
  opacity: 1;
  visibility: visible;
}

/* line 274, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
.owl-carousel:hover .owl-nav div:hover {
  color: #fff;
  background: #FD8E5E;
  border: 1px solid transparent;
}

/* line 284, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
.mb-20px {
  margin-bottom: 20px;
}

/* line 288, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
.mb-55 {
  margin-bottom: 55px;
}

/* line 291, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
.mb-40 {
  margin-bottom: 40px;
}

/* line 294, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
.mb-20 {
  margin-bottom: 20px;
}

/* line 299, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
.mb-60 {
  margin-bottom: 50px !important;
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 299, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
  .mb-60 {
    margin-bottom: 30px !important;
  }
}

@media (max-width: 767px) {
  /* line 299, ../../Arafath/CL/December/235. Job board/HTML/scss/theme-default.scss */
  .mb-60 {
    margin-bottom: 20px !important;
  }
}

/* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_btn.scss */
.boxed-btn {
  background: #fff;
  color: #131313;
  display: inline-block;
  padding: 14px 44px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  border: 0;
  border: 1px solid #00D363;
  text-align: center;
  color: #00D363 !important;
  text-transform: uppercase;
  cursor: pointer;
}

/* line 15, ../../Arafath/CL/December/235. Job board/HTML/scss/_btn.scss */
.boxed-btn:hover {
  background: #00D363;
  color: #fff !important;
  border: 1px solid #00D363;
}

/* line 20, ../../Arafath/CL/December/235. Job board/HTML/scss/_btn.scss */
.boxed-btn:focus {
  outline: none;
}

/* line 24, ../../Arafath/CL/December/235. Job board/HTML/scss/_btn.scss */
.boxed-btn3 {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#F91842+0,fd8e5e+100 */
  background: #00D363;
  color: #fff;
  display: inline-block;
  padding: 13px 29px 13px 29px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  border: 0;
  border: 1px solid transparent;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  text-align: center;
  color: #fff !important;
  text-transform: capitalize;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}

/* line 43, ../../Arafath/CL/December/235. Job board/HTML/scss/_btn.scss */
.boxed-btn3:hover {
  color: #00D363 !important;
  border: 1px solid #00D363;
  background: transparent;
}

/* line 48, ../../Arafath/CL/December/235. Job board/HTML/scss/_btn.scss */
.boxed-btn3:focus {
  outline: none;
}

/* line 52, ../../Arafath/CL/December/235. Job board/HTML/scss/_btn.scss */
.boxed-btn4 {
  display: inline-block;
  padding: 11px 29px 13px 29px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  text-align: center;
  color: #fff !important;
  text-transform: capitalize;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  color: #00D363 !important;
  border: 1px solid #00D363;
  background: transparent;
}

/* line 67, ../../Arafath/CL/December/235. Job board/HTML/scss/_btn.scss */
.boxed-btn4:hover {
  color: #fff !important;
  border: 1px solid #00D363;
  background: transparent;
  border: 0;
  background: #00D363;
  border: 1px solid transparent;
}

/* line 75, ../../Arafath/CL/December/235. Job board/HTML/scss/_btn.scss */
.boxed-btn4:focus {
  outline: none;
}

/* line 79, ../../Arafath/CL/December/235. Job board/HTML/scss/_btn.scss */
.boxed-btn3-line {
  color: #F91842 !important;
  display: inline-block;
  padding: 14px 31px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 500;
  border: 0;
  border: 1px solid #F91842;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  text-align: center;
  text-transform: capitalize;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
}

/* line 94, ../../Arafath/CL/December/235. Job board/HTML/scss/_btn.scss */
.boxed-btn3-line:hover {
  color: #fff !important;
  border: 1px solid transparent;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#F91842+0,fd8e5e+100 */
  background: #fdae5c;
  /* Old browsers */
  background: -moz-linear-gradient(left, #fdae5c 0%, #fd8e5e 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #fdae5c 0%, #fd8e5e 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #fdae5c 0%, #fd8e5e 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F91842', endColorstr='#fd8e5e',GradientType=1 );
  /* IE6-9 */
}

/* line 105, ../../Arafath/CL/December/235. Job board/HTML/scss/_btn.scss */
.boxed-btn3-line:focus {
  outline: none;
}

/* line 108, ../../Arafath/CL/December/235. Job board/HTML/scss/_btn.scss */
.boxed-btn3-line.large-width {
  width: 220px;
}

/* line 112, ../../Arafath/CL/December/235. Job board/HTML/scss/_btn.scss */
.boxed-btn2 {
  border: none;
  background: #F91842;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  padding: 12px 36px;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
}

/* line 125, ../../Arafath/CL/December/235. Job board/HTML/scss/_btn.scss */
.boxed-btn2:hover {
  background: #F91842;
  color: #fff !important;
}

/* line 129, ../../Arafath/CL/December/235. Job board/HTML/scss/_btn.scss */
.boxed-btn2:focus {
  outline: none;
}

/* line 133, ../../Arafath/CL/December/235. Job board/HTML/scss/_btn.scss */
.line-button {
  color: #919191;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  position: relative;
  padding-right: 5px;
  padding-bottom: 2px;
}

/* line 141, ../../Arafath/CL/December/235. Job board/HTML/scss/_btn.scss */
.line-button::before {
  position: absolute;
  content: "";
  background: #919191;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
}

/* line 150, ../../Arafath/CL/December/235. Job board/HTML/scss/_btn.scss */
.line-button:hover {
  color: #009DFF;
}

/* line 153, ../../Arafath/CL/December/235. Job board/HTML/scss/_btn.scss */
.line-button:hover::before {
  background: #009DFF;
}

/* line 157, ../../Arafath/CL/December/235. Job board/HTML/scss/_btn.scss */
.book_now {
  display: inline-block;
  font-size: 14px;
  color: #009DFF;
  border: 1px solid #009DFF;
  text-transform: capitalize;
  padding: 10px 25px;
}

/* line 164, ../../Arafath/CL/December/235. Job board/HTML/scss/_btn.scss */
.book_now:hover {
  background: #009DFF;
  color: #fff;
}

/* line 2, ../../Arafath/CL/December/235. Job board/HTML/scss/_section_title.scss */
.section_title span {
  display: inline-block;
  background: #33D4D6;
  width: 60px;
  height: 3px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  margin-bottom: 4px;
}

/* line 10, ../../Arafath/CL/December/235. Job board/HTML/scss/_section_title.scss */
.section_title h3 {
  font-size: 38px;
  font-weight: 400;
  color: #001D38;
  position: relative;
  z-index: 0;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  /* line 10, ../../Arafath/CL/December/235. Job board/HTML/scss/_section_title.scss */
  .section_title h3 {
    font-size: 30px;
    line-height: 36px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 10, ../../Arafath/CL/December/235. Job board/HTML/scss/_section_title.scss */
  .section_title h3 {
    font-size: 36px;
    line-height: 42px;
  }
}

@media (max-width: 767px) {
  /* line 25, ../../Arafath/CL/December/235. Job board/HTML/scss/_section_title.scss */
  .section_title h3 br {
    display: none;
  }
}

/* line 31, ../../Arafath/CL/December/235. Job board/HTML/scss/_section_title.scss */
.section_title p {
  font-size: 16px;
  color: #7A838B;
  margin-bottom: 0;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

@media (max-width: 767px) {
  /* line 37, ../../Arafath/CL/December/235. Job board/HTML/scss/_section_title.scss */
  .section_title p br {
    display: none;
  }
}

/* line 45, ../../Arafath/CL/December/235. Job board/HTML/scss/_section_title.scss */
.section_title h4 {
  font-size: 22px;
  font-weight: 500;
  color: #001D38;
  padding-bottom: 21px;
}

/* line 51, ../../Arafath/CL/December/235. Job board/HTML/scss/_section_title.scss */
.mb-60 {
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  /* line 51, ../../Arafath/CL/December/235. Job board/HTML/scss/_section_title.scss */
  .mb-60 {
    margin-bottom: 40px;
  }
}

/* line 57, ../../Arafath/CL/December/235. Job board/HTML/scss/_section_title.scss */
.mb-90 {
  margin-bottom: 90px;
}

@media (max-width: 767px) {
  /* line 57, ../../Arafath/CL/December/235. Job board/HTML/scss/_section_title.scss */
  .mb-90 {
    margin-bottom: 40px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 57, ../../Arafath/CL/December/235. Job board/HTML/scss/_section_title.scss */
  .mb-90 {
    margin-bottom: 60px;
  }
}

/* line 67, ../../Arafath/CL/December/235. Job board/HTML/scss/_section_title.scss */
.pl-68 {
  padding-left: 68px;
}

@media (max-width: 767px) {
  /* line 67, ../../Arafath/CL/December/235. Job board/HTML/scss/_section_title.scss */
  .pl-68 {
    padding-left: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 67, ../../Arafath/CL/December/235. Job board/HTML/scss/_section_title.scss */
  .pl-68 {
    padding-left: 0;
  }
}

/* line 76, ../../Arafath/CL/December/235. Job board/HTML/scss/_section_title.scss */
.mb-40 {
  margin-bottom: 39px;
}

@media (max-width: 767px) {
  /* line 76, ../../Arafath/CL/December/235. Job board/HTML/scss/_section_title.scss */
  .mb-40 {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  /* line 4, ../../Arafath/CL/December/235. Job board/HTML/scss/_slick-nav.scss */
  .mobile_menu {
    position: absolute;
    right: 0px;
    width: 100%;
    z-index: 9;
  }
}

/* line 13, ../../Arafath/CL/December/235. Job board/HTML/scss/_slick-nav.scss */
.slicknav_menu .slicknav_nav {
  background: #fff;
  float: right;
  margin-top: 0;
  padding: 0;
  width: 95%;
  padding: 0;
  border-radius: 0px;
  margin-top: 5px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 11px;
}

/* line 28, ../../Arafath/CL/December/235. Job board/HTML/scss/_slick-nav.scss */
.slicknav_menu .slicknav_nav a:hover {
  background: transparent;
  color: #F91842;
}

/* line 32, ../../Arafath/CL/December/235. Job board/HTML/scss/_slick-nav.scss */
.slicknav_menu .slicknav_nav a.active {
  color: #F91842;
}

@media (max-width: 767px) {
  /* line 35, ../../Arafath/CL/December/235. Job board/HTML/scss/_slick-nav.scss */
  .slicknav_menu .slicknav_nav a i {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 35, ../../Arafath/CL/December/235. Job board/HTML/scss/_slick-nav.scss */
  .slicknav_menu .slicknav_nav a i {
    display: none;
  }
}

/* line 44, ../../Arafath/CL/December/235. Job board/HTML/scss/_slick-nav.scss */
.slicknav_menu .slicknav_nav .slicknav_btn {
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: -40px;
  position: relative;
  z-index: 99;
  border: 1px solid #ddd;
  top: 3px;
  right: 5px;
  top: -36px;
}

/* line 55, ../../Arafath/CL/December/235. Job board/HTML/scss/_slick-nav.scss */
.slicknav_menu .slicknav_nav .slicknav_btn .slicknav_icon {
  margin-right: 6px;
  margin-top: 3px;
  position: relative;
  padding-bottom: 3px;
  top: -11px;
  right: -5px;
}

@media (max-width: 767px) {
  /* line 12, ../../Arafath/CL/December/235. Job board/HTML/scss/_slick-nav.scss */
  .slicknav_menu {
    margin-right: 0px;
  }
}

/* line 72, ../../Arafath/CL/December/235. Job board/HTML/scss/_slick-nav.scss */
.slicknav_nav .slicknav_arrow {
  float: right;
  font-size: 22px;
  position: relative;
  top: -9px;
}

/* line 78, ../../Arafath/CL/December/235. Job board/HTML/scss/_slick-nav.scss */
.slicknav_btn {
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
  z-index: 99;
  border: none;
  border-radius: 3px;
  top: 5px;
  padding: 5px;
  right: 5px;
  margin-top: -5px;
  top: -31px;
}

/* line 92, ../../Arafath/CL/December/235. Job board/HTML/scss/_slick-nav.scss */
.slicknav_btn {
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
  z-index: 99;
  border: none;
  border-radius: 3px;
  top: 5px;
  padding: 5px;
  right: 0;
  margin-top: -5px;
  top: -33px;
}

/* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area {
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
  z-index: 9;
  position: absolute;
}

@media (max-width: 767px) {
  /* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area {
    padding-top: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area {
    padding-top: 0;
  }
}

/* line 16, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area {
  background: #fff;
  background: transparent;
  padding: 0px 150px;
}

/* line 20, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area.details_nav_bg {
  background: #727272;
  padding-bottom: 0;
}

@media (max-width: 767px) {
  /* line 20, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .main-header-area.details_nav_bg {
    padding-bottom: 10px;
  }
}

/* line 27, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area.details_nav {
  background: #001D38;
}

@media (max-width: 767px) {
  /* line 16, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .main-header-area {
    padding: 10px 10px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 16, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .main-header-area {
    padding: 10px 10px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 16, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .main-header-area {
    padding: 0px 20px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  /* line 16, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .main-header-area {
    padding: 0px 20px;
  }
}

@media (min-width: 1501px) and (max-width: 1800px) {
  /* line 16, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .main-header-area {
    padding: 0px 50px;
  }
}

/* line 47, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area .logo-img {
  text-align: center;
}

@media (max-width: 767px) {
  /* line 47, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .main-header-area .logo-img {
    text-align: left;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 47, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .main-header-area .logo-img {
    text-align: left;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 47, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .main-header-area .logo-img {
    text-align: left;
  }
}

@media (max-width: 767px) {
  /* line 61, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .main-header-area .logo-img img {
    width: 70px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 61, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .main-header-area .logo-img img {
    width: 70px;
  }
}

@media (max-width: 767px) {
  /* line 73, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .main-header-area .logo img {
    max-width: 160px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 73, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .main-header-area .logo img {
    max-width: 160px;
  }
}

/* line 82, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area .Appointment {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end;
}

/* line 86, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area .Appointment .phone_num {
  margin-right: 30px;
}

@media (min-width: 1200px) and (max-width: 1500px) {
  /* line 86, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .main-header-area .Appointment .phone_num {
    margin-right: 12px;
  }
}

/* line 91, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area .Appointment .phone_num a {
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
}

@media (min-width: 1200px) and (max-width: 1500px) {
  /* line 91, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .main-header-area .Appointment .phone_num a {
    font-size: 13px;
  }
}

/* line 98, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area .Appointment .phone_num a i {
  color: #33D4D6;
  font-size: 14px;
  margin-right: 7px;
}

@media (min-width: 1200px) and (max-width: 1500px) {
  /* line 105, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .main-header-area .Appointment .boxed-btn4 {
    padding: 11px 9px 13px 9px;
    font-size: 13px;
  }
}

@media (min-width: 1501px) and (max-width: 1800px) {
  /* line 105, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .main-header-area .Appointment .boxed-btn4 {
    padding: 11px 20px 13px 20px;
    font-size: 13px;
  }
}

/* line 117, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area .Appointment .socail_links ul li {
  display: inline-block;
}

/* line 120, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area .Appointment .socail_links ul li a {
  color: #A8A7A0;
  margin: 0 10px;
  font-size: 15px;
}

/* line 124, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area .Appointment .socail_links ul li a:hover {
  color: #fff;
}

/* line 160, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu {
  text-align: center;
}

/* line 163, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li {
  display: inline-block;
  position: relative;
  margin: 0 25px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 163, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .main-header-area .main-menu ul li {
    margin: 0 14px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  /* line 163, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .main-header-area .main-menu ul li {
    margin: 0 14px;
  }
}

/* line 176, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li a {
  color: #fff;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 500;
  display: inline-block;
  padding: 35px 0px 35px 0px;
  font-family: "Roboto", sans-serif;
  position: relative;
  text-transform: capitalize;
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 176, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .main-header-area .main-menu ul li a {
    font-size: 15px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  /* line 176, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .main-header-area .main-menu ul li a {
    font-size: 13px;
  }
}

/* line 193, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li a i {
  font-size: 9px;
}

@media (max-width: 767px) {
  /* line 193, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .main-header-area .main-menu ul li a i {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 193, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .main-header-area .main-menu ul li a i {
    display: none !important;
  }
}

/* line 224, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li a:hover {
  color: #00D363;
}

/* line 228, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li .submenu {
  position: absolute;
  left: 0;
  top: 120%;
  background: #fff;
  width: 200px;
  z-index: 2;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.02);
  opacity: 0;
  visibility: hidden;
  text-align: left;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

/* line 240, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li .submenu li {
  display: block;
  margin: 0;
}

/* line 243, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li .submenu li a {
  padding: 10px 15px;
  position: inherit;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  display: block;
  color: #000;
}

/* line 249, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li .submenu li a::before {
  display: none;
}

/* line 253, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li .submenu li:hover a {
  color: #000;
}

/* line 258, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li:hover > .submenu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

/* line 263, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li:hover > a::before {
  opacity: 1;
}

/* line 267, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li:first-child a {
  padding-left: 0;
}

/* line 273, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area.sticky {
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: -70px;
  left: 0;
  right: 0;
  z-index: 990;
  transform: translateY(70px);
  transition: transform 500ms ease, background 500ms ease;
  -webkit-transition: transform 500ms ease, background 500ms ease;
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
  background: rgba(0, 29, 56, 0.8);
}

@media (max-width: 767px) {
  /* line 273, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .main-header-area.sticky {
    padding: 10px 10px;
  }
}

/* line 300, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area.sticky .main-menu {
  padding: 0;
}

/* line 303, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area.sticky .header_bottom_border {
  border-bottom: none;
}

/* line 306, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .main-header-area.sticky .header_bottom_border.white_border {
  border-bottom: none !important;
}

/* line 312, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .header-top_area {
  background: #001D38;
}

@media (max-width: 767px) {
  /* line 314, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .header-top_area .social_media_links {
    text-align: center;
  }
}

/* line 318, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .header-top_area .social_media_links a {
  font-size: 15px;
  color: #C7C7C7;
  margin-right: 12px;
}

/* line 322, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .header-top_area .social_media_links a:hover {
  color: #00D363;
}

/* line 328, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .header-top_area .header_left p {
  color: #727272;
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 0;
}

/* line 335, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .header-top_area .short_contact_list {
  text-align: right;
}

@media (max-width: 767px) {
  /* line 335, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .header-top_area .short_contact_list {
    text-align: center;
  }
}

/* line 341, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .header-top_area .short_contact_list ul li {
  display: inline-block;
}

/* line 343, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .header-top_area .short_contact_list ul li a {
  font-size: 13px;
  color: #919191;
  margin-right: 50px;
}

@media (max-width: 767px) {
  /* line 343, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .header-top_area .short_contact_list ul li a {
    margin-left: 0;
    margin: 0 5px;
  }
}

/* line 352, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .header-top_area .short_contact_list ul li a i {
  color: #00D363;
  margin-right: 7px;
}

/* line 360, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .header-top_area .header_right.d-flex {
  display: flex;
  justify-content: flex-end;
}

/* line 365, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
.header-area .search_btn i {
  color: #fff;
  font-size: 15px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 369, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .search_btn {
    position: relative;
    right: 10px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  /* line 369, ../../Arafath/CL/December/235. Job board/HTML/scss/_header.scss */
  .header-area .search_btn {
    position: relative;
    right: 10px;
  }
}

/* line 10, ../../Arafath/CL/December/235. Job board/HTML/scss/_slider.scss */
.slider_area .single_slider {
  height: 700px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@media (max-width: 767px) {
  /* line 10, ../../Arafath/CL/December/235. Job board/HTML/scss/_slider.scss */
  .slider_area .single_slider {
    height: auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 10, ../../Arafath/CL/December/235. Job board/HTML/scss/_slider.scss */
  .slider_area .single_slider {
    height: 700px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 10, ../../Arafath/CL/December/235. Job board/HTML/scss/_slider.scss */
  .slider_area .single_slider {
    height: 550px;
  }
}

@media (max-width: 767px) {
  /* line 24, ../../Arafath/CL/December/235. Job board/HTML/scss/_slider.scss */
  .slider_area .single_slider .slider_text {
    margin-top: 120px;
    margin-bottom: 100px;
  }
}

/* line 29, ../../Arafath/CL/December/235. Job board/HTML/scss/_slider.scss */
.slider_area .single_slider .slider_text h5 {
  font-size: 30px;
  color: #fff;
  font-weight: 400;
}

/* line 34, ../../Arafath/CL/December/235. Job board/HTML/scss/_slider.scss */
.slider_area .single_slider .slider_text h3 {
  font-family: "Roboto", sans-serif;
  font-size: 56px;
  font-weight: 500;
  color: #fff;
}

@media (max-width: 767px) {
  /* line 34, ../../Arafath/CL/December/235. Job board/HTML/scss/_slider.scss */
  .slider_area .single_slider .slider_text h3 {
    font-size: 30px;
    margin-bottom: 30px;
    line-height: 45px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 34, ../../Arafath/CL/December/235. Job board/HTML/scss/_slider.scss */
  .slider_area .single_slider .slider_text h3 {
    font-size: 34px;
    line-height: 1.4;
    margin-bottom: 35px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 34, ../../Arafath/CL/December/235. Job board/HTML/scss/_slider.scss */
  .slider_area .single_slider .slider_text h3 {
    font-size: 40px;
    line-height: 1.4;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  /* line 34, ../../Arafath/CL/December/235. Job board/HTML/scss/_slider.scss */
  .slider_area .single_slider .slider_text h3 {
    font-size: 60px;
    font-weight: 400;
    line-height: 74px;
  }
}

/* line 59, ../../Arafath/CL/December/235. Job board/HTML/scss/_slider.scss */
.slider_area .single_slider .slider_text p {
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  font-family: "Roboto", sans-serif;
  margin-bottom: 35px;
}

/* line 66, ../../Arafath/CL/December/235. Job board/HTML/scss/_slider.scss */
.slider_area .single_slider .slider_text .button_search {
  text-align: right;
}

@media (max-width: 767px) {
  /* line 66, ../../Arafath/CL/December/235. Job board/HTML/scss/_slider.scss */
  .slider_area .single_slider .slider_text .button_search {
    text-align: left;
  }
}

/* line 72, ../../Arafath/CL/December/235. Job board/HTML/scss/_slider.scss */
.slider_area .single_slider .slider_text .quality {
  margin-top: 40px;
}

@media (max-width: 767px) {
  /* line 72, ../../Arafath/CL/December/235. Job board/HTML/scss/_slider.scss */
  .slider_area .single_slider .slider_text .quality {
    margin-top: 20px;
  }
}

/* line 78, ../../Arafath/CL/December/235. Job board/HTML/scss/_slider.scss */
.slider_area .single_slider .slider_text .quality ul li {
  display: inline-block;
}

@media (max-width: 767px) {
  /* line 78, ../../Arafath/CL/December/235. Job board/HTML/scss/_slider.scss */
  .slider_area .single_slider .slider_text .quality ul li {
    margin-bottom: 10px;
  }
}

/* line 83, ../../Arafath/CL/December/235. Job board/HTML/scss/_slider.scss */
.slider_area .single_slider .slider_text .quality ul li button {
  border: none;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
  font-weight: #000;
  font-family: "Roboto", sans-serif;
  padding: 5px 19px;
  display: inline-block;
  margin: 0 3px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}

/* line 96, ../../Arafath/CL/December/235. Job board/HTML/scss/_slider.scss */
.slider_area .single_slider .slider_text .quality ul li button:hover {
  background: #F91842;
}

/* line 105, ../../Arafath/CL/December/235. Job board/HTML/scss/_slider.scss */
.slider_area .ilstration_img {
  position: absolute;
  right: 0;
  bottom: 0;
  right: 7%;
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 105, ../../Arafath/CL/December/235. Job board/HTML/scss/_slider.scss */
  .slider_area .ilstration_img {
    right: 0;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 113, ../../Arafath/CL/December/235. Job board/HTML/scss/_slider.scss */
  .slider_area .ilstration_img img {
    width: 75%;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  /* line 113, ../../Arafath/CL/December/235. Job board/HTML/scss/_slider.scss */
  .slider_area .ilstration_img img {
    width: 75%;
  }
}

/* line 123, ../../Arafath/CL/December/235. Job board/HTML/scss/_slider.scss */
.gj-picker-md div[role="navigator"] {
  padding-left: 30px !important;
}

/* line 128, ../../Arafath/CL/December/235. Job board/HTML/scss/_slider.scss */
.gj-datepicker-md [role="right-icon"] {
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 16px;
  color: #919191;
  margin-right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

/* line 138, ../../Arafath/CL/December/235. Job board/HTML/scss/_slider.scss */
.nice-select::after {
  content: "\f0d7";
  display: block;
  pointer-events: none;
  position: absolute;
  right: 30px;
  top: 50%;
  transition: all 0.15s ease-in-out;
  width: 5px;
  font-family: 'fontawesome';
  color: #ddd;
  transform: translateY(-50%);
  font-size: 16px;
  right: 25px;
  color: #AAB1B7;
  height: auto;
  margin-top: 0;
}

/* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_about.scss */
.about_area {
  margin-bottom: 150px;
}

/* line 3, ../../Arafath/CL/December/235. Job board/HTML/scss/_about.scss */
.about_area.plus_padding {
  padding-top: 150px;
}

@media (max-width: 767px) {
  /* line 3, ../../Arafath/CL/December/235. Job board/HTML/scss/_about.scss */
  .about_area.plus_padding {
    padding-top: 60px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 3, ../../Arafath/CL/December/235. Job board/HTML/scss/_about.scss */
  .about_area.plus_padding {
    padding-top: 100px;
  }
}

@media (max-width: 767px) {
  /* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_about.scss */
  .about_area {
    margin-bottom: 50px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_about.scss */
  .about_area {
    margin-bottom: 100px;
  }
}

@media (max-width: 767px) {
  /* line 18, ../../Arafath/CL/December/235. Job board/HTML/scss/_about.scss */
  .about_area .about_img {
    margin-bottom: 20px;
  }
}

/* line 22, ../../Arafath/CL/December/235. Job board/HTML/scss/_about.scss */
.about_area .about_img img {
  width: 100%;
}

/* line 27, ../../Arafath/CL/December/235. Job board/HTML/scss/_about.scss */
.about_area .about_info .section_title {
  margin-bottom: 36px;
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 27, ../../Arafath/CL/December/235. Job board/HTML/scss/_about.scss */
  .about_area .about_info .section_title {
    margin-bottom: 20px;
  }
}

/* line 33, ../../Arafath/CL/December/235. Job board/HTML/scss/_about.scss */
.about_area .about_info p {
  color: #7A838B;
  font-size: 16px;
}

/* line 37, ../../Arafath/CL/December/235. Job board/HTML/scss/_about.scss */
.about_area .about_info .about_list {
  margin-bottom: 52px;
  margin-top: 10px;
}

/* line 41, ../../Arafath/CL/December/235. Job board/HTML/scss/_about.scss */
.about_area .about_info .about_list ul li {
  font-size: 16px;
  line-height: 32px;
  color: #040E27;
  font-weight: 400;
  position: relative;
  padding-left: 23px;
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 41, ../../Arafath/CL/December/235. Job board/HTML/scss/_about.scss */
  .about_area .about_info .about_list ul li {
    font-size: 14px;
    line-height: 28px;
  }
}

/* line 52, ../../Arafath/CL/December/235. Job board/HTML/scss/_about.scss */
.about_area .about_info .about_list ul li::before {
  position: absolute;
  left: 0;
  width: 8px;
  height: 8px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #0A8CFF;
  content: '';
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
.testimonial_area {
  padding: 100px 0;
}

@media (max-width: 767px) {
  /* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
  .testimonial_area {
    padding: 50px 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
  .testimonial_area {
    padding: 100px 0;
  }
}

@media (max-width: 767px) {
  /* line 9, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
  .testimonial_area .single_testmonial {
    display: block !important;
  }
}

/* line 13, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial .thumb {
  position: relative;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 228px;
  height: 228px;
}

/* line 18, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial .thumb img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

/* line 21, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial .thumb .quote_icon {
  position: absolute;
  width: 58px;
  height: 58px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #00D363;
  color: #fff;
  text-align: center;
  line-height: 58px;
  right: 0;
  bottom: 0;
}

/* line 34, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial .info {
  padding-left: 66px;
  padding-right: 15px;
}

@media (max-width: 767px) {
  /* line 34, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
  .testimonial_area .single_testmonial .info {
    padding: 0;
    margin-top: 10px;
  }
}

/* line 41, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial .info p {
  font-size: 18px;
  color: #040E27;
  line-height: 32px;
  margin-bottom: 18px;
}

/* line 47, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial .info span {
  color: #7A838B;
  font-size: 16px;
  font-weight: 400;
  display: block;
}

/* line 55, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
.testimonial_area .owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
}

/* line 61, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
.testimonial_area .owl-carousel .owl-nav div {
  font-size: 15px;
  line-height: 50px;
  border: 0;
  left: auto;
  background: #E6F3FF;
  color: #0A8CFF;
  right: 0;
  left: auto;
  line-height: 51px;
}

/* line 71, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
.testimonial_area .owl-carousel .owl-nav div:hover {
  border: none;
  background: #7FC1FC;
  color: #fff;
}

/* line 76, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
.testimonial_area .owl-carousel .owl-nav div.owl-next {
  margin-top: -30px;
}

/* line 79, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
.testimonial_area .owl-carousel .owl-nav div.owl-prev {
  margin-top: 30px;
}

/* line 2, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion.scss */
#accordion button {
  font-size: 16px;
  color: #0f2137;
  font-weight: 500;
  font-weight: 500;
}

@media (max-width: 767px) {
  /* line 2, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion.scss */
  #accordion button {
    font-size: 14px;
  }
}

/* line 11, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion.scss */
#accordion .card {
  margin-bottom: 15px;
  border-radius: 0;
  border-radius: 0 !important;
  margin-bottom: 30px;
}

/* line 16, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion.scss */
#accordion .card .card-header {
  background-color: transparent;
  padding: 4px 14px;
}

@media (max-width: 767px) {
  /* line 16, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion.scss */
  #accordion .card .card-header {
    padding: 4px 0;
  }
}

/* line 23, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion.scss */
#accordion .card .card-body {
  font-size: 16px;
  line-height: 28px;
  color: #888888;
  padding: 10px 30px 32px;
}

/* line 29, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion.scss */
#accordion .card .collapse {
  border-bottom: 1px solid #d7dbe3;
}

/* line 31, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion.scss */
#accordion .card .collapse.show {
  border-bottom: 1px solid transparent;
}

/* line 39, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion.scss */
#accordion h5 {
  position: relative;
  z-index: 1;
}

/* line 65, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion.scss */
#accordion h5 button.btn.btn-link:after {
  position: absolute;
  content: "\e648";
  top: 9px;
  right: 12px;
  height: auto;
  font-family: 'themify';
  color: #0f2137;
  font-size: 14px;
  left: -24px;
}

@media (max-width: 767px) {
  /* line 65, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion.scss */
  #accordion h5 button.btn.btn-link:after {
    right: 10px;
  }
}

/* line 81, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion.scss */
#accordion h5 button.btn.btn-link.collapsed:after {
  content: "\e64b";
  color: #0f2137;
  font-size: 14px;
}

/* line 88, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion.scss */
#accordion h5 button i {
  height: 36px;
  width: 36px;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  line-height: 36px;
  border-radius: 50%;
  margin-right: 20px;
  color: #fff;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 767px) {
  /* line 88, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion.scss */
  #accordion h5 button i {
    display: none;
  }
}

/* line 104, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion.scss */
#accordion h5 button.btn.btn-link.collapsed i {
  background: #f4f7f9;
  color: #0f2137;
}

@media (max-width: 767px) {
  /* line 104, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion.scss */
  #accordion h5 button.btn.btn-link.collapsed i {
    display: none;
  }
}

/* line 116, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion.scss */
#accordion .btn-link:hover {
  color: #182028;
  text-decoration: none;
}

/* line 120, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion.scss */
#accordion .btn {
  text-align: left !important;
}

/* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion_area.scss */
.accordion_area {
  position: relative;
}

/* line 3, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion_area.scss */
.accordion_area.extra_padding {
  margin-top: 150px;
}

@media (max-width: 767px) {
  /* line 3, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion_area.scss */
  .accordion_area.extra_padding {
    margin-top: 50px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 3, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion_area.scss */
  .accordion_area.extra_padding {
    margin-top: 100px;
  }
}

@media (min-width: 991px) {
  /* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion_area.scss */
  .accordion_area {
    height: 700px !important;
  }
}


@media (max-width: 767px) {
  /* line 15, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion_area.scss */
  .accordion_area::before {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 15, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion_area.scss */
  .accordion_area::before {
    display: none;
  }
}

@media (max-width: 767px) {
  /* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion_area.scss */
  .accordion_area {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion_area.scss */
  .accordion_area {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

/* line 40, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion_area.scss */
.accordion_area .accordion_thumb {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
  padding-left: 68px;
}

@media (max-width: 767px) {
  /* line 40, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion_area.scss */
  .accordion_area .accordion_thumb {
    padding-left: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 40, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion_area.scss */
  .accordion_area .accordion_thumb {
    padding-left: 0;
  }
}

/* line 50, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion_area.scss */
.accordion_area .accordion_thumb img {
  width: 100%;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

/* line 55, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion_area.scss */
.accordion_area .faq_ask {
  padding-top: 130px;
  padding-bottom: 130px;
}

@media (max-width: 767px) {
  /* line 55, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion_area.scss */
  .accordion_area .faq_ask {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 55, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion_area.scss */
  .accordion_area .faq_ask {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

/* line 66, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion_area.scss */
.accordion_area .faq_ask h3 {
  font-size: 44px;
  font-weight: 400;
  color: #040E27;
  margin-bottom: 50px;
}

/* line 73, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion_area.scss */
.accordion_area .faq_ask #accordion .card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: none;
  border-radius: .25rem;
  border-bottom: 1px solid #E8E8E8 !important;
}

/* line 90, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion_area.scss */
.accordion_area .faq_ask #accordion .card .card-header {
  background-color: transparent;
  padding: 4px 14px;
  border: none;
}

/* line 95, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion_area.scss */
.accordion_area .faq_ask #accordion button {
  font-size: 18px;
  color: #040E27 !important;
  font-weight: 400 !important;
}

@media (max-width: 767px) {
  /* line 99, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion_area.scss */
  .accordion_area .faq_ask #accordion button span {
    display: none;
  }
}

/* line 105, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion_area.scss */
.accordion_area .faq_ask #accordion .card .card-header {
  background-color: transparent;
  padding: 4px 30px;
}

/* line 119, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion_area.scss */
.accordion_area .faq_ask #accordion .card .card-body {
  font-size: 15px;
  line-height: 28px;
  color: #727272;
  padding: 10px 30px 32px;
  font-weight: 400;
  line-height: 28px;
}

/* line 129, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion_area.scss */
.accordion_area .faq_ask #accordion .btn-link.focus, .accordion_area .faq_ask #accordion .btn-link:focus {
  text-decoration: none;
  border-color: transparent;
  box-shadow: none;
}

/* line 137, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion_area.scss */
.accordion_area .card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0;
  border-radius: .25rem;
  margin-bottom: 9px !important;
}

/* line 154, ../../Arafath/CL/December/235. Job board/HTML/scss/_accordion_area.scss */
.accordion_area .btn {
  white-space: normal !important;
}

/* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer {
  background-repeat: no-repeat;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background: #001D38;
}

/* line 7, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer p {
  font-family: "Roboto", sans-serif !important;
}

/* line 10, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .footer_top {
  padding-top: 145px;
  padding-bottom: 129px;
}

@media (max-width: 767px) {
  /* line 10, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
  .footer .footer_top {
    padding-top: 60px;
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) {
  /* line 17, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
  .footer .footer_top .footer_widget {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 17, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
  .footer .footer_top .footer_widget {
    margin-bottom: 30px;
  }
}

/* line 24, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .footer_title {
  font-size: 18px;
  font-weight: 500;
  color: #FFFFFF;
  text-transform: capitalize;
  margin-bottom: 40px;
  font-family: "Roboto", sans-serif;
}

@media (max-width: 767px) {
  /* line 24, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
  .footer .footer_top .footer_widget .footer_title {
    margin-bottom: 20px;
  }
}

/* line 35, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .footer_logo {
  font-size: 22px;
  font-weight: 400;
  color: #FFFFFF;
  text-transform: capitalize;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  /* line 35, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
  .footer .footer_top .footer_widget .footer_logo {
    margin-bottom: 20px;
  }
}

/* line 45, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p {
  color: #AAB1B7;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

/* line 51, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p a {
  color: #AAB1B7;
}

/* line 53, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p a:hover {
  color: #FFFFFF;
}

/* line 59, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text {
  font-size: 16px;
  color: #00D363;
  margin-bottom: 23px;
  font-weight: 400;
  line-height: 28px;
}

/* line 65, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text a.domain {
  color: #00D363;
  font-weight: 400;
}

/* line 68, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text a.domain:hover {
  color: #FFFFFF;
  border-bottom: 1px solid #FFFFFF;
}

/* line 74, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text.doanar a {
  font-weight: 500;
  color: #00D363;
}

/* line 78, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text.doanar a:hover {
  color: #FFFFFF;
  border-bottom: 1px solid #FFFFFF;
}

/* line 82, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget p.footer_text.doanar a.first {
  margin-bottom: 10px;
}

/* line 90, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget ul li {
  color: #AAB1B7;
  font-size: 14px;
  line-height: 42px;
}

/* line 94, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget ul li a {
  color: #AAB1B7;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

/* line 98, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget ul li a:hover {
  color: #FFFFFF;
}

/* line 104, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_form {
  position: relative;
  margin-bottom: 20px;
}

/* line 107, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_form input {
  width: 100%;
  height: 50px;
  background: transparent;
  padding-left: 20px;
  font-size: 15px;
  color: #FFFFFF;
  border: none;
  border: 1px solid #334A60;
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
}

/* line 118, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_form input::placeholder {
  font-size: 14px;
  color: #AAB1B7;
}

/* line 122, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_form input:focus {
  outline: none;
}

/* line 126, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_form button {
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  border: none;
  font-size: 14px;
  color: #fff;
  background: #00D363;
  padding: 10px;
  padding: 0 22px;
  cursor: pointer;
  border-radius: 5px;
  top: 5px;
  right: 5px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

/* line 146, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .footer_top .footer_widget .newsletter_text {
  font-size: 14px;
  color: #AAB1B7;
  line-height: 26px;
}

/* line 153, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .copy-right_text {
  padding-bottom: 30px;
}

/* line 155, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .copy-right_text .copy_right {
  font-size: 15px;
  color: #AAB1B7;
  margin-bottom: 0;
  font-weight: 500;
}

@media (max-width: 767px) {
  /* line 155, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
  .footer .copy-right_text .copy_right {
    font-size: 14px;
  }
}

/* line 163, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .copy-right_text .copy_right a {
  color: #00D363;
}

/* line 168, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .socail_links {
  margin-top: 47px;
}

@media (max-width: 767px) {
  /* line 168, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
  .footer .socail_links {
    margin-top: 30px;
  }
}

/* line 174, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .socail_links ul li {
  display: inline-block;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* line 177, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .socail_links ul li a {
  font-size: 15px;
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  background: transparent;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  line-height: 40px !important;
  margin-right: 7px;
  color: #fff !important;
  line-height: 40px !important;
  background: transparent;
  color: #334A60 !important;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border: 1px solid #334A60;
}

/* line 193, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .socail_links ul li a i {
  font-size: 15px;
  line-height: 40px;
  position: relative;
  top: -1px;
}

/* line 201, ../../Arafath/CL/December/235. Job board/HTML/scss/_footer.scss */
.footer .socail_links ul li:hover a {
  color: #fff !important;
  background: #00D363;
  border-color: transparent;
  border: 1px solid #00D363;
}

/* line 4, ../../Arafath/CL/December/235. Job board/HTML/scss/_bradcam.scss */
.bradcam_area {
  background-size: cover;
  background-position: center;
  padding: 182px 0 102px 0;
  background-repeat: no-repeat;
  position: relative;
  z-index: 0;
  background-size: cover;
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 4, ../../Arafath/CL/December/235. Job board/HTML/scss/_bradcam.scss */
  .bradcam_area {
    padding: 120px 0;
  }
}

@media (max-width: 767px) {
  /* line 4, ../../Arafath/CL/December/235. Job board/HTML/scss/_bradcam.scss */
  .bradcam_area {
    padding: 150px 0 60px 0;
  }
}

/* line 19, ../../Arafath/CL/December/235. Job board/HTML/scss/_bradcam.scss */
.bradcam_area h3 {
  font-size: 56px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 0;
  text-transform: capitalize;
}

@media (max-width: 767px) {
  /* line 19, ../../Arafath/CL/December/235. Job board/HTML/scss/_bradcam.scss */
  .bradcam_area h3 {
    font-size: 30px;
  }
}

/* line 29, ../../Arafath/CL/December/235. Job board/HTML/scss/_bradcam.scss */
.bradcam_area p {
  font-size: 18px;
  color: #fff;
  font-weight: 400;
  text-transform: capitalize;
}

/* line 34, ../../Arafath/CL/December/235. Job board/HTML/scss/_bradcam.scss */
.bradcam_area p a {
  color: #fff;
}

/* line 36, ../../Arafath/CL/December/235. Job board/HTML/scss/_bradcam.scss */
.bradcam_area p a:hover {
  color: #fff;
}

/* line 42, ../../Arafath/CL/December/235. Job board/HTML/scss/_bradcam.scss */
.bradcam_area .icon {
  background: #FF5748;
  width: 136px;
  height: 136px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  line-height: 136px;
  margin: auto;
}

/* line 50, ../../Arafath/CL/December/235. Job board/HTML/scss/_bradcam.scss */
.bradcam_area .icon i {
  color: #fff;
  font-size: 68px;
}

/* line 56, ../../Arafath/CL/December/235. Job board/HTML/scss/_bradcam.scss */
.bradcam_area h4 {
  font-size: 70px;
  font-weight: 300;
  color: #fff;
  margin: 10px 0;
}

@media (max-width: 767px) {
  /* line 56, ../../Arafath/CL/December/235. Job board/HTML/scss/_bradcam.scss */
  .bradcam_area h4 {
    font-size: 40px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 56, ../../Arafath/CL/December/235. Job board/HTML/scss/_bradcam.scss */
  .bradcam_area h4 {
    font-size: 40px;
  }
}

/* line 68, ../../Arafath/CL/December/235. Job board/HTML/scss/_bradcam.scss */
.bradcam_area p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

/* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_category.scss */
.catagory_area {
  padding: 100px 0;
}

@media (max-width: 767px) {
  /* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_category.scss */
  .catagory_area {
    padding: 70px 0;
  }
}

/* line 6, ../../Arafath/CL/December/235. Job board/HTML/scss/_category.scss */
.catagory_area .cat_search {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 30px;
}

/* line 11, ../../Arafath/CL/December/235. Job board/HTML/scss/_category.scss */
.catagory_area .cat_search .single_input input {
  height: 50px;
  border: 1px solid #E8E8E8;
  padding: 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  width: 100%;
}

@media (max-width: 767px) {
  /* line 11, ../../Arafath/CL/December/235. Job board/HTML/scss/_category.scss */
  .catagory_area .cat_search .single_input input {
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 11, ../../Arafath/CL/December/235. Job board/HTML/scss/_category.scss */
  .catagory_area .cat_search .single_input input {
    margin-bottom: 20px;
  }
}

/* line 23, ../../Arafath/CL/December/235. Job board/HTML/scss/_category.scss */
.catagory_area .cat_search .single_input input::placeholder {
  color: #7A838B;
  font-size: 16px;
  font-weight: 400;
}

/* line 29, ../../Arafath/CL/December/235. Job board/HTML/scss/_category.scss */
.catagory_area .cat_search .single_input input:focus {
  outline: none;
}

/* line 34, ../../Arafath/CL/December/235. Job board/HTML/scss/_category.scss */
.catagory_area .cat_search .single_input .nice-select {
  width: 100%;
  height: 50px;
  line-height: 50px;
  color: #7A838B;
  font-size: 16px;
}

@media (max-width: 767px) {
  /* line 34, ../../Arafath/CL/December/235. Job board/HTML/scss/_category.scss */
  .catagory_area .cat_search .single_input .nice-select {
    margin-bottom: 20px;
  }
}

/* line 43, ../../Arafath/CL/December/235. Job board/HTML/scss/_category.scss */
.catagory_area .cat_search .single_input .nice-select:focus {
  outline: none;
}

/* line 50, ../../Arafath/CL/December/235. Job board/HTML/scss/_category.scss */
.catagory_area .cat_search .job_btn a {
  width: 100%;
  display: block !important;
}

@media (max-width: 767px) {
  /* line 56, ../../Arafath/CL/December/235. Job board/HTML/scss/_category.scss */
  .catagory_area .popular_search {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 56, ../../Arafath/CL/December/235. Job board/HTML/scss/_category.scss */
  .catagory_area .popular_search {
    display: block !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 56, ../../Arafath/CL/December/235. Job board/HTML/scss/_category.scss */
  .catagory_area .popular_search {
    display: block !important;
  }
}

/* line 66, ../../Arafath/CL/December/235. Job board/HTML/scss/_category.scss */
.catagory_area .popular_search span {
  font-size: 18px;
  font-weight: 400;
  color: #001D38;
  margin-right: 20px;
}

@media (max-width: 767px) {
  /* line 66, ../../Arafath/CL/December/235. Job board/HTML/scss/_category.scss */
  .catagory_area .popular_search span {
    margin-bottom: 20px;
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 66, ../../Arafath/CL/December/235. Job board/HTML/scss/_category.scss */
  .catagory_area .popular_search span {
    margin-bottom: 20px;
    display: block;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 66, ../../Arafath/CL/December/235. Job board/HTML/scss/_category.scss */
  .catagory_area .popular_search span {
    margin-bottom: 20px;
    display: block;
  }
}

/* line 86, ../../Arafath/CL/December/235. Job board/HTML/scss/_category.scss */
.catagory_area .popular_search ul li {
  display: inline-block;
  margin-right: 7px;
}

@media (max-width: 767px) {
  /* line 86, ../../Arafath/CL/December/235. Job board/HTML/scss/_category.scss */
  .catagory_area .popular_search ul li {
    margin-bottom: 15px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 86, ../../Arafath/CL/December/235. Job board/HTML/scss/_category.scss */
  .catagory_area .popular_search ul li {
    margin-bottom: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 86, ../../Arafath/CL/December/235. Job board/HTML/scss/_category.scss */
  .catagory_area .popular_search ul li {
    margin-bottom: 15px;
  }
}

/* line 98, ../../Arafath/CL/December/235. Job board/HTML/scss/_category.scss */
.catagory_area .popular_search ul li a {
  color: #0A8CFF;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #CEE8FF;
  display: inline-block;
  border-radius: 5px;
  padding: 7px 14px;
}

@media (max-width: 767px) {
  /* line 98, ../../Arafath/CL/December/235. Job board/HTML/scss/_category.scss */
  .catagory_area .popular_search ul li a {
    font-size: 13px;
  }
}

/* line 109, ../../Arafath/CL/December/235. Job board/HTML/scss/_category.scss */
.catagory_area .popular_search ul li a:hover {
  border-color: #0A8CFF;
}

/* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_popular_catagory.scss */
.popular_catagory_area {
  background: #F5F7FA;
  padding: 100px 0;
}

/* line 4, ../../Arafath/CL/December/235. Job board/HTML/scss/_popular_catagory.scss */
.popular_catagory_area .single_catagory {
  background: #FFFFFF;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 38px 25px;
  border: 1px solid transparent;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  margin-bottom: 30px;
}

/* line 12, ../../Arafath/CL/December/235. Job board/HTML/scss/_popular_catagory.scss */
.popular_catagory_area .single_catagory a h4 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 15px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* line 21, ../../Arafath/CL/December/235. Job board/HTML/scss/_popular_catagory.scss */
.popular_catagory_area .single_catagory p {
  font-size: 16px;
  color: #AAB1B7;
  margin-bottom: 0;
  font-weight: 400;
}

/* line 26, ../../Arafath/CL/December/235. Job board/HTML/scss/_popular_catagory.scss */
.popular_catagory_area .single_catagory p span {
  background: #E6F3FF;
  color: #0A8CFF;
  font-size: 16px;
  font-weight: 400;
  padding: 6px 17px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-right: 10px;
}

/* line 36, ../../Arafath/CL/December/235. Job board/HTML/scss/_popular_catagory.scss */
.popular_catagory_area .single_catagory:hover {
  border-color: #B2F2D0;
}

/* line 37, ../../Arafath/CL/December/235. Job board/HTML/scss/_popular_catagory.scss */
.popular_catagory_area .single_catagory:hover h4 {
  color: #00D363;
  text-decoration: underline;
}

/* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_job.scss */
.job_searcing_wrap {
  position: relative;
  z-index: 0;
  padding: 116px 0;
  background: #2B9BFF !important;
}

@media (max-width: 767px) {
  /* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_job.scss */
  .job_searcing_wrap {
    padding: 30px 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_job.scss */
  .job_searcing_wrap {
    padding: 60px 0;
  }
}

/* line 12, ../../Arafath/CL/December/235. Job board/HTML/scss/_job.scss */
.job_searcing_wrap.overlay::before {
  background: #0A8CFF;
  opacity: .8;
  width: 50%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}

@media (max-width: 767px) {
  /* line 12, ../../Arafath/CL/December/235. Job board/HTML/scss/_job.scss */
  .job_searcing_wrap.overlay::before {
    height: 50%;
    top: 0;
    left: 0;
    width: 100%;
  }
}

/* line 27, ../../Arafath/CL/December/235. Job board/HTML/scss/_job.scss */
.job_searcing_wrap::after {
  width: 50%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  left: 0;
  top: 0;
  content: '';
  position: absolute;
}

@media (max-width: 767px) {
  /* line 27, ../../Arafath/CL/December/235. Job board/HTML/scss/_job.scss */
  .job_searcing_wrap::after {
    height: 50%;
    top: 0;
    left: 0;
    width: 100%;
  }
}

/* line 45, ../../Arafath/CL/December/235. Job board/HTML/scss/_job.scss */
.job_searcing_wrap .searching_text {
  position: relative;
  z-index: 8;
}

@media (max-width: 767px) {
  /* line 45, ../../Arafath/CL/December/235. Job board/HTML/scss/_job.scss */
  .job_searcing_wrap .searching_text {
    margin: 30px 0;
  }
}

/* line 51, ../../Arafath/CL/December/235. Job board/HTML/scss/_job.scss */
.job_searcing_wrap .searching_text h3 {
  font-size: 38px;
  font-weight: 400;
  color: #FFFFFF;
}

@media (max-width: 767px) {
  /* line 51, ../../Arafath/CL/December/235. Job board/HTML/scss/_job.scss */
  .job_searcing_wrap .searching_text h3 {
    font-size: 25px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 51, ../../Arafath/CL/December/235. Job board/HTML/scss/_job.scss */
  .job_searcing_wrap .searching_text h3 {
    font-size: 25px;
  }
}

/* line 62, ../../Arafath/CL/December/235. Job board/HTML/scss/_job.scss */
.job_searcing_wrap .searching_text p {
  font-weight: 400;
  color: #FFFFFF;
  font-size: 16px;
  margin-bottom: 30px;
  margin-top: 12px;
}

/* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
.testimonial_area {
  padding: 100px 0;
}

@media (max-width: 767px) {
  /* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
  .testimonial_area {
    padding: 50px 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
  .testimonial_area {
    padding: 100px 0;
  }
}

@media (max-width: 767px) {
  /* line 9, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
  .testimonial_area .single_testmonial {
    display: block !important;
  }
}

/* line 13, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial .thumb {
  position: relative;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 228px;
  height: 228px;
}

/* line 18, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial .thumb img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

/* line 21, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial .thumb .quote_icon {
  position: absolute;
  width: 58px;
  height: 58px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #00D363;
  color: #fff;
  text-align: center;
  line-height: 58px;
  right: 0;
  bottom: 0;
}

/* line 34, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial .info {
  padding-left: 66px;
  padding-right: 15px;
}

@media (max-width: 767px) {
  /* line 34, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
  .testimonial_area .single_testmonial .info {
    padding: 0;
    margin-top: 10px;
  }
}

/* line 41, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial .info p {
  font-size: 18px;
  color: #040E27;
  line-height: 32px;
  margin-bottom: 18px;
}

/* line 47, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial .info span {
  color: #7A838B;
  font-size: 16px;
  font-weight: 400;
  display: block;
}

/* line 55, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
.testimonial_area .owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
}

/* line 61, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
.testimonial_area .owl-carousel .owl-nav div {
  font-size: 15px;
  line-height: 50px;
  border: 0;
  left: auto;
  background: #E6F3FF;
  color: #0A8CFF;
  right: 0;
  left: auto;
  line-height: 51px;
}

/* line 71, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
.testimonial_area .owl-carousel .owl-nav div:hover {
  border: none;
  background: #7FC1FC;
  color: #fff;
}

/* line 76, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
.testimonial_area .owl-carousel .owl-nav div.owl-next {
  margin-top: -30px;
}

/* line 79, ../../Arafath/CL/December/235. Job board/HTML/scss/_testmonial.scss */
.testimonial_area .owl-carousel .owl-nav div.owl-prev {
  margin-top: 30px;
}

/* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_service.scss */
.service_area {
  padding-top: 150px;
  padding-bottom: 120px;
}

@media (max-width: 767px) {
  /* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_service.scss */
  .service_area {
    padding-top: 50px;
    padding-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_service.scss */
  .service_area {
    padding-top: 100px;
    padding-bottom: 70px;
  }
}

/* line 12, ../../Arafath/CL/December/235. Job board/HTML/scss/_service.scss */
.service_area .single_service {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0a8cff+0,054680+100 */
  background: #0a8cff;
  /* Old browsers */
  background: -moz-linear-gradient(top, #0a8cff 0%, #054680 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #0a8cff 0%, #054680 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #0a8cff 0%, #054680 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0a8cff', endColorstr='#054680',GradientType=0 );
  /* IE6-9 */
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 30px;
}

/* line 21, ../../Arafath/CL/December/235. Job board/HTML/scss/_service.scss */
.service_area .single_service .service_icon {
  margin: 50px auto 43px auto;
  display: inline-block;
}

/* line 26, ../../Arafath/CL/December/235. Job board/HTML/scss/_service.scss */
.service_area .single_service .info span {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  display: block;
  margin-bottom: 15px;
}

/* line 33, ../../Arafath/CL/December/235. Job board/HTML/scss/_service.scss */
.service_area .single_service .info h3 {
  color: #33D4D6;
  font-weight: 400;
  font-size: 30px;
}

/* line 39, ../../Arafath/CL/December/235. Job board/HTML/scss/_service.scss */
.service_area .single_service .service_content {
  padding: 30px 50px 50px 50px;
  margin-top: 28px;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 39, ../../Arafath/CL/December/235. Job board/HTML/scss/_service.scss */
  .service_area .single_service .service_content {
    padding: 30px 23px 50px 23px;
  }
}

/* line 47, ../../Arafath/CL/December/235. Job board/HTML/scss/_service.scss */
.service_area .single_service .service_content ul li {
  font-size: 16px;
  line-height: 32px;
  color: #fff;
  font-weight: 400;
  position: relative;
  z-index: 0;
  padding-left: 18px;
}

/* line 55, ../../Arafath/CL/December/235. Job board/HTML/scss/_service.scss */
.service_area .single_service .service_content ul li::before {
  width: 8px;
  height: 8px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #33D4D6;
}

/* line 68, ../../Arafath/CL/December/235. Job board/HTML/scss/_service.scss */
.service_area .single_service .service_content .apply_btn {
  margin-top: 40px;
}

/* line 70, ../../Arafath/CL/December/235. Job board/HTML/scss/_service.scss */
.service_area .single_service .service_content .apply_btn button {
  width: 100%;
}

/* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.job_listing_area {
  background: #F5F7FA;
  padding-bottom: 80px;
}

/* line 4, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.job_listing_area.plus_padding {
  padding-top: 100px;
}

@media (max-width: 767px) {
  /* line 4, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
  .job_listing_area.plus_padding {
    padding-top: 60px;
  }
}

@media (max-width: 767px) {
  /* line 10, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
  .job_listing_area .brouse_job {
    text-align: left !important;
    margin-top: 20px;
  }
}

/* line 16, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.job_listing_area .job_lists {
  margin-top: 50px;
}

/* line 18, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.job_listing_area .job_lists .single_jobs {
  padding: 30px;
  margin-bottom: 12px;
  border: 1px solid transparent;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

@media (max-width: 767px) {
  /* line 18, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
  .job_listing_area .job_lists .single_jobs {
    display: block !important;
  }
}

@media (max-width: 767px) {
  /* line 27, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
  .job_listing_area .job_lists .single_jobs .jobs_left {
    display: block !important;
    overflow: hidden;
  }
}

/* line 32, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.job_listing_area .job_lists .single_jobs .jobs_left .thumb {
  float: left;
  width: 82px;
  height: 82px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 15px;
  background: #F5F7FA;
  margin-right: 25px;
  border: 1px solid #F0F0F0;
}

/* line 46, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.job_listing_area .job_lists .single_jobs .jobs_left .jobs_conetent {
  float: left;
}

/* line 48, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.job_listing_area .job_lists .single_jobs .jobs_left .jobs_conetent h4 {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 400;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 767px) {
  /* line 48, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
  .job_listing_area .job_lists .single_jobs .jobs_left .jobs_conetent h4 {
    margin-top: 15px;
  }
}

/* line 56, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.job_listing_area .job_lists .single_jobs .jobs_left .jobs_conetent h4:hover {
  color: #00D363;
}

@media (max-width: 767px) {
  /* line 60, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
  .job_listing_area .job_lists .single_jobs .jobs_left .jobs_conetent .links_locat {
    display: block !important;
  }
}

/* line 64, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.job_listing_area .job_lists .single_jobs .jobs_left .jobs_conetent .links_locat .location {
  margin-right: 50px;
}

@media (max-width: 767px) {
  /* line 64, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
  .job_listing_area .job_lists .single_jobs .jobs_left .jobs_conetent .links_locat .location {
    margin-right: 10px;
  }
}

/* line 69, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.job_listing_area .job_lists .single_jobs .jobs_left .jobs_conetent .links_locat .location p {
  margin-bottom: 0;
  font-size: 16px;
  color: #AAB1B7;
}

/* line 73, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.job_listing_area .job_lists .single_jobs .jobs_left .jobs_conetent .links_locat .location p i {
  margin-right: 7px;
}

@media (max-width: 767px) {
  /* line 82, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
  .job_listing_area .job_lists .single_jobs .jobs_right .apply_now {
    margin: 10px 0;
  }
}

/* line 87, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.job_listing_area .job_lists .single_jobs .jobs_right .apply_now a.heart_mark {
  width: 40px;
  height: 40px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: #00D363;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  background: #EFFDF5;
  margin-right: 15px;
}

/* line 98, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.job_listing_area .job_lists .single_jobs .jobs_right .apply_now a.heart_mark:hover {
  background: #00D363;
  color: #fff;
}

/* line 103, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.job_listing_area .job_lists .single_jobs .jobs_right .apply_now a.boxed-btn3 {
  padding: 9px 27px 9px 27px;
  font-size: 14px;
}

/* line 109, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.job_listing_area .job_lists .single_jobs .jobs_right .date {
  text-align: right;
  margin-top: 10px;
}

@media (max-width: 767px) {
  /* line 109, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
  .job_listing_area .job_lists .single_jobs .jobs_right .date {
    text-align: left;
  }
}

/* line 115, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.job_listing_area .job_lists .single_jobs .jobs_right .date p {
  margin-bottom: 0;
  font-size: 14px;
  font-style: italic;
  color: #7A838B;
}

/* line 123, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.job_listing_area .job_lists .single_jobs:hover {
  border-color: #B2F2D0;
}

/* line 130, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.job_listing_area .job_filter {
  padding: 25px;
  display: block;
}

/* line 133, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.job_listing_area .job_filter h3 {
  font-size: 20px;
  margin-bottom: 25px;
  color: #001D38;
  font-weight: 400;
}

/* line 141, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.job_listing_area .job_filter .form_inner .single_field input {
  height: 60px;
  border: 1px solid #E8E8E8;
  padding: 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 20px;
}

/* line 148, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.job_listing_area .job_filter .form_inner .single_field input::placeholder {
  color: #7A838B;
  font-size: 16px;
  font-weight: 400;
}

/* line 154, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.job_listing_area .job_filter .form_inner .single_field input:focus {
  outline: none;
}

/* line 159, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.job_listing_area .job_filter .form_inner .single_field .nice-select {
  width: 100%;
  height: 60px;
  line-height: 60px;
  color: #7A838B;
  font-size: 16px;
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  /* line 159, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
  .job_listing_area .job_filter .form_inner .single_field .nice-select {
    margin-bottom: 20px;
  }
}

/* line 169, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.job_listing_area .job_filter .form_inner .single_field .nice-select:focus {
  outline: none;
}

/* line 175, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.job_listing_area .job_filter .form_inner .nice-select {
  width: 100%;
  height: 50px;
  line-height: 50px;
  color: #7A838B;
  font-size: 16px;
}

@media (max-width: 767px) {
  /* line 175, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
  .job_listing_area .job_filter .form_inner .nice-select {
    margin-bottom: 20px;
  }
}

/* line 184, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.job_listing_area .job_filter .form_inner .nice-select:focus {
  outline: none;
}

/* line 192, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.recent_joblist {
  padding: 15px 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  /* line 192, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
  .recent_joblist {
    margin-top: 40px;
  }
}

/* line 199, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.recent_joblist h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  color: #001D38;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  /* line 199, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
  .recent_joblist h4 {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  /* line 209, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
  .recent_joblist .serch_cat {
    display: block !important;
  }
}

/* line 216, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_list.scss */
.recent_joblist .nice-select {
  width: 180px;
  height: 50px;
  line-height: 50px;
  color: #7A838B;
  font-size: 16px;
}

/* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_candidates.scss */
.featured_candidates_area {
  background: #F5F7FA;
  padding-bottom: 100px;
}

/* line 4, ../../Arafath/CL/December/235. Job board/HTML/scss/_candidates.scss */
.featured_candidates_area.candidate_page_padding {
  padding-top: 100px;
}

/* line 6, ../../Arafath/CL/December/235. Job board/HTML/scss/_candidates.scss */
.featured_candidates_area.candidate_page_padding .single_candidates {
  margin-bottom: 30px;
}

/* line 10, ../../Arafath/CL/December/235. Job board/HTML/scss/_candidates.scss */
.featured_candidates_area .single_candidates {
  background: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 35px 45px;
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 10, ../../Arafath/CL/December/235. Job board/HTML/scss/_candidates.scss */
  .featured_candidates_area .single_candidates {
    padding: 25px 30px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 10, ../../Arafath/CL/December/235. Job board/HTML/scss/_candidates.scss */
  .featured_candidates_area .single_candidates {
    padding: 25px 20px;
  }
}

/* line 20, ../../Arafath/CL/December/235. Job board/HTML/scss/_candidates.scss */
.featured_candidates_area .single_candidates .thumb {
  width: 110px;
  height: 110px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  margin: auto;
}

/* line 25, ../../Arafath/CL/December/235. Job board/HTML/scss/_candidates.scss */
.featured_candidates_area .single_candidates .thumb img {
  width: 100%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

/* line 30, ../../Arafath/CL/December/235. Job board/HTML/scss/_candidates.scss */
.featured_candidates_area .single_candidates h4 {
  font-size: 20px;
  font-weight: 400;
  color: #001D38;
  margin-top: 24px;
  margin-bottom: 6px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* line 38, ../../Arafath/CL/December/235. Job board/HTML/scss/_candidates.scss */
.featured_candidates_area .single_candidates p {
  font-size: 16px;
  font-weight: 400;
  color: #AAB1B7;
  margin-bottom: 0;
}

/* line 45, ../../Arafath/CL/December/235. Job board/HTML/scss/_candidates.scss */
.featured_candidates_area .single_candidates:hover h4 {
  color: #00D363;
}

/* line 50, ../../Arafath/CL/December/235. Job board/HTML/scss/_candidates.scss */
.featured_candidates_area .owl-carousel .owl-nav div {
  background: rgba(10, 140, 255, 0.1);
  height: 38px;
  left: 0px;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 38px;
  color: #0A8CFF;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  left: 50px;
  font-size: 15px;
  line-height: 38px;
  border: none;
  left: -19px;
}

/* line 73, ../../Arafath/CL/December/235. Job board/HTML/scss/_candidates.scss */
.featured_candidates_area .owl-carousel .owl-nav div.owl-next {
  left: auto;
  right: -19px;
}

/* line 77, ../../Arafath/CL/December/235. Job board/HTML/scss/_candidates.scss */
.featured_candidates_area .owl-carousel .owl-nav div:hover {
  background: rgba(10, 140, 255, 0.5);
  color: #fff;
  border: none;
}

/* line 86, ../../Arafath/CL/December/235. Job board/HTML/scss/_candidates.scss */
.pagination_wrap {
  text-align: center;
  margin-top: 20px;
}

/* line 90, ../../Arafath/CL/December/235. Job board/HTML/scss/_candidates.scss */
.pagination_wrap ul li {
  display: inline-block;
}

/* line 92, ../../Arafath/CL/December/235. Job board/HTML/scss/_candidates.scss */
.pagination_wrap ul li a {
  width: 38px;
  height: 38px;
  border: 1px solid #EAEAEA;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  font-size: 16px;
  text-align: center;
  line-height: 38px;
  display: inline-block;
  color: #AAB1B7;
  font-size: 14px;
  margin: 0 4px;
}

/* line 104, ../../Arafath/CL/December/235. Job board/HTML/scss/_candidates.scss */
.pagination_wrap ul li a i {
  line-height: 38px;
}

/* line 107, ../../Arafath/CL/December/235. Job board/HTML/scss/_candidates.scss */
.pagination_wrap ul li a span {
  line-height: 38px;
}

/* line 110, ../../Arafath/CL/December/235. Job board/HTML/scss/_candidates.scss */
.pagination_wrap ul li a:hover {
  border-color: #00D363;
  color: #00D363;
}

/* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_compani.scss */
.top_companies_area {
  background: #F5F7FA;
  padding-bottom: 70px;
}

@media (max-width: 767px) {
  /* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_compani.scss */
  .top_companies_area {
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  /* line 7, ../../Arafath/CL/December/235. Job board/HTML/scss/_compani.scss */
  .top_companies_area .brouse_job {
    text-align: left !important;
    margin-top: 15px;
  }
}

/* line 16, ../../Arafath/CL/December/235. Job board/HTML/scss/_compani.scss */
.top_companies_area .single_company {
  background: #fff;
  padding: 25px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 30px;
}

/* line 21, ../../Arafath/CL/December/235. Job board/HTML/scss/_compani.scss */
.top_companies_area .single_company .thumb {
  width: 82px;
  height: 82px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 15px;
  background: #F5F7FA;
  margin-right: 25px;
  border: 1px solid #F0F0F0;
}

/* line 32, ../../Arafath/CL/December/235. Job board/HTML/scss/_compani.scss */
.top_companies_area .single_company h3 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 18px;
  margin-top: 20px;
}

/* line 38, ../../Arafath/CL/December/235. Job board/HTML/scss/_compani.scss */
.top_companies_area .single_company p {
  font-size: 16px;
  color: #AAB1B7;
  margin-bottom: 0;
  font-weight: 400;
}

/* line 43, ../../Arafath/CL/December/235. Job board/HTML/scss/_compani.scss */
.top_companies_area .single_company p span {
  background: #E6F3FF;
  color: #0A8CFF;
  font-size: 16px;
  font-weight: 400;
  padding: 6px 17px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-right: 10px;
}

/* line 3, ../../Arafath/CL/December/235. Job board/HTML/scss/_range.scss */
.job_filter .range_wrap label {
  font-size: 16px;
  color: #001D38;
  font-weight: 400;
  margin-bottom: 19px;
  margin-top: 4px;
}

/* line 10, ../../Arafath/CL/December/235. Job board/HTML/scss/_range.scss */
.job_filter span.range {
  color: #040E27;
  font-size: 16px;
  font-weight: 400;
  display: block;
  margin-bottom: 15px;
}

/* line 17, ../../Arafath/CL/December/235. Job board/HTML/scss/_range.scss */
.job_filter p {
  margin-top: 14px;
  margin-bottom: 27px;
}

/* line 20, ../../Arafath/CL/December/235. Job board/HTML/scss/_range.scss */
.job_filter p input {
  background-color: transparent;
}

/* line 24, ../../Arafath/CL/December/235. Job board/HTML/scss/_range.scss */
.job_filter .ui-widget.ui-widget-content {
  border: 0;
  background: #D1FFE7;
}

/* line 28, ../../Arafath/CL/December/235. Job board/HTML/scss/_range.scss */
.job_filter .ui-slider-horizontal {
  height: 2px;
}

/* line 31, ../../Arafath/CL/December/235. Job board/HTML/scss/_range.scss */
.job_filter .ui-state-default, .job_filter .ui-widget-content .ui-state-default, .job_filter .ui-widget-header .ui-state-default, .job_filter .ui-button, .job_filter html .ui-button.ui-state-disabled:hover, .job_filter html .ui-button.ui-state-disabled:active {
  border: 2px solid #00D363;
  background: #fff;
  font-weight: normal;
  width: 14px;
  height: 16px;
  border-radius: 5px;
}

/* line 39, ../../Arafath/CL/December/235. Job board/HTML/scss/_range.scss */
.job_filter .ui-widget-header {
  background: #00D363;
}

/* line 42, ../../Arafath/CL/December/235. Job board/HTML/scss/_range.scss */
.job_filter .ui-slider-horizontal .ui-slider-handle {
  top: -7px;
  margin-left: -.6em;
}

/* line 49, ../../Arafath/CL/December/235. Job board/HTML/scss/_range.scss */
.job_filter .range_wrap p input::placeholder {
  font-size: 14px !important;
  color: #7A838B !important;
  font-weight: 400 !important;
}

/* line 1, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area {
  background: #F5F7FA;
  padding-top: 100px;
  padding-bottom: 100px;
}

/* line 5, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .single_jobs {
  padding: 30px;
  border-bottom: 1px solid #EAEAEA;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

@media (max-width: 767px) {
  /* line 5, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
  .job_details_area .single_jobs {
    display: block !important;
  }
}

@media (max-width: 767px) {
  /* line 13, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
  .job_details_area .single_jobs .jobs_left {
    display: block !important;
    overflow: hidden;
  }
}

/* line 18, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .single_jobs .jobs_left .thumb {
  float: left;
  width: 82px;
  height: 82px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 15px;
  background: #F5F7FA;
  margin-right: 25px;
  border: 1px solid #F0F0F0;
}

/* line 32, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .single_jobs .jobs_left .jobs_conetent {
  float: left;
}

/* line 34, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .single_jobs .jobs_left .jobs_conetent h4 {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 400;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 767px) {
  /* line 34, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
  .job_details_area .single_jobs .jobs_left .jobs_conetent h4 {
    margin-top: 15px;
  }
}

/* line 42, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .single_jobs .jobs_left .jobs_conetent h4:hover {
  color: #00D363;
}

@media (max-width: 767px) {
  /* line 46, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
  .job_details_area .single_jobs .jobs_left .jobs_conetent .links_locat {
    display: block !important;
  }
}

/* line 50, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .single_jobs .jobs_left .jobs_conetent .links_locat .location {
  margin-right: 50px;
}

@media (max-width: 767px) {
  /* line 50, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
  .job_details_area .single_jobs .jobs_left .jobs_conetent .links_locat .location {
    margin-right: 10px;
  }
}

/* line 55, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .single_jobs .jobs_left .jobs_conetent .links_locat .location p {
  margin-bottom: 0;
  font-size: 16px;
  color: #AAB1B7;
}

/* line 59, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .single_jobs .jobs_left .jobs_conetent .links_locat .location p i {
  margin-right: 7px;
}

@media (max-width: 767px) {
  /* line 68, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
  .job_details_area .single_jobs .jobs_right .apply_now {
    margin: 10px 0;
  }
}

/* line 73, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .single_jobs .jobs_right .apply_now a.heart_mark {
  width: 40px;
  height: 40px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: #00D363;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  background: #EFFDF5;
}

/* line 83, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .single_jobs .jobs_right .apply_now a.heart_mark:hover {
  background: #00D363;
  color: #fff;
}

/* line 88, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .single_jobs .jobs_right .apply_now a.boxed-btn3 {
  padding: 9px 27px 9px 27px;
  font-size: 14px;
}

/* line 94, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .single_jobs .jobs_right .date {
  text-align: right;
  margin-top: 10px;
}

@media (max-width: 767px) {
  /* line 94, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
  .job_details_area .single_jobs .jobs_right .date {
    text-align: left;
  }
}

/* line 100, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .single_jobs .jobs_right .date p {
  margin-bottom: 0;
  font-size: 14px;
  font-style: italic;
  color: #7A838B;
}

/* line 110, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .descript_wrap {
  padding: 40px;
}

/* line 112, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .descript_wrap .single_wrap {
  margin-bottom: 30px;
}

/* line 114, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .descript_wrap .single_wrap:last-child {
  margin-bottom: 0;
}

/* line 117, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .descript_wrap .single_wrap h4 {
  font-size: 20px;
  font-weight: 500;
  color: #001D38;
  margin-bottom: 25px;
}

/* line 123, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .descript_wrap .single_wrap p {
  color: #7A838B;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
}

/* line 130, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .descript_wrap .single_wrap ul li {
  font-size: 16px;
  line-height: 32px;
  color: #7A838B;
  font-weight: 400;
  position: relative;
  padding-left: 25px;
}

/* line 137, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .descript_wrap .single_wrap ul li::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 7px;
  height: 7px;
  background: #7A838B;
  content: '';
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  top: 12px;
}

/* line 152, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .apply_job_form {
  margin-top: 30px;
  padding: 40px;
}

@media (max-width: 767px) {
  /* line 152, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
  .job_details_area .apply_job_form {
    padding: 30px;
  }
}

/* line 158, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .apply_job_form h4 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 30px;
}

/* line 164, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .apply_job_form .input_field input, .job_details_area .apply_job_form .input_field textarea {
  height: 60px;
  border: 1px solid #E8E8E8;
  width: 100%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding-left: 20px;
  margin-bottom: 20px;
}

/* line 171, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .apply_job_form .input_field input::placeholder, .job_details_area .apply_job_form .input_field textarea::placeholder {
  color: #7A838B;
  font-size: 16px;
}

/* line 175, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .apply_job_form .input_field input:focus, .job_details_area .apply_job_form .input_field textarea:focus {
  outline: none;
}

/* line 179, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .apply_job_form .input_field textarea {
  height: 188px;
  padding: 20px;
}

/* line 184, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .apply_job_form .input_field.file_up input {
  position: relative;
}

/* line 186, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .apply_job_form .input_field.file_up input:before {
  position: absolute;
  left: 0;
  top: 0;
  content: 'Upload CV';
}

/* line 195, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .apply_job_form .input-group {
  width: 100%;
  height: 60px;
  border-radius: 5px !important;
  margin-bottom: 20px;
  border: 1px solid #ddd;
}

/* line 201, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .apply_job_form .input-group button {
  background: transparent;
  border: none;
  font-size: 16px;
  color: #7A838B;
  padding-left: 20px;
  margin-right: 5px;
}

/* line 209, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .apply_job_form .input-group .custom-file {
  margin-bottom: 0;
  height: 60px;
  border: none;
}

/* line 214, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .apply_job_form .input-group .custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  padding: 0;
  line-height: 60px;
  color: #7A838B;
  background-color: transparent;
  border-radius: 0;
  border: none;
}

/* line 228, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .apply_job_form .input-group .custom-file-input {
  height: 100%;
}

/* line 233, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .job_sumary {
  background: #fff;
}

@media (max-width: 767px) {
  /* line 233, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
  .job_details_area .job_sumary {
    margin-top: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* line 233, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
  .job_details_area .job_sumary {
    margin-top: 30px;
  }
}

/* line 241, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .job_sumary .summery_header {
  border-bottom: 1px solid #EAEAEA;
  padding: 40px;
}

@media (max-width: 767px) {
  /* line 241, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
  .job_details_area .job_sumary .summery_header {
    padding: 30px;
  }
}

/* line 247, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .job_sumary .summery_header h3 {
  font-size: 24px;
  color: #001D38;
  font-weight: 400;
}

/* line 253, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .job_sumary .job_content {
  padding: 40px;
}

@media (max-width: 767px) {
  /* line 253, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
  .job_details_area .job_sumary .job_content {
    padding: 30px;
  }
}

/* line 259, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .job_sumary .job_content ul li {
  font-size: 16px;
  font-weight: 400;
  color: #AAB1B7;
  line-height: 38px;
  padding-left: 18px;
  position: relative;
}

/* line 266, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .job_sumary .job_content ul li::before {
  position: absolute;
  width: 8px;
  height: 8px;
  border: 1px solid #AAB1B7;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  left: 0;
  content: '';
  top: 16px;
}

/* line 277, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .job_sumary .job_content ul li span {
  color: #001D38;
}

/* line 284, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .share_wrap {
  background: #fff;
  margin: 30px 0;
  padding: 20px;
}

/* line 288, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .share_wrap span {
  font-size: 16px;
  font-weight: 500;
  color: #001D38;
  margin-right: 20px;
}

/* line 295, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .share_wrap ul li {
  display: inline-block;
  margin-right: 10px;
}

/* line 298, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .share_wrap ul li a {
  font-size: 16px;
  color: #D5D5D5;
}

/* line 301, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .share_wrap ul li a:hover {
  color: #00D363;
}

/* line 308, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.job_details_area .job_location_wrap {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  overflow: hidden;
}

/* line 314, ../../Arafath/CL/December/235. Job board/HTML/scss/_job_details.scss */
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(calc(2.25rem + 2px) - 1px * 2);
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 .25rem .25rem 0;
  margin-bottom: 0;
  height: 60px;
  display: none !important;
}

/*=================== contact banner start ====================*/
/* line 3, ../../Arafath/CL/December/235. Job board/HTML/scss/_contact.scss */
.dropdown .dropdown-menu {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 10, ../../Arafath/CL/December/235. Job board/HTML/scss/_contact.scss */
.contact-info {
  margin-bottom: 25px;
}

/* line 13, ../../Arafath/CL/December/235. Job board/HTML/scss/_contact.scss */
.contact-info__icon {
  margin-right: 20px;
}

/* line 16, ../../Arafath/CL/December/235. Job board/HTML/scss/_contact.scss */
.contact-info__icon i, .contact-info__icon span {
  color: #8f9195;
  font-size: 27px;
}

/* line 24, ../../Arafath/CL/December/235. Job board/HTML/scss/_contact.scss */
.contact-info .media-body h3 {
  font-size: 16px;
  margin-bottom: 0;
  font-size: 16px;
  color: #2a2a2a;
}

/* line 30, ../../Arafath/CL/December/235. Job board/HTML/scss/_contact.scss */
.contact-info .media-body h3 a:hover {
  color: #ff5e13;
}

/* line 36, ../../Arafath/CL/December/235. Job board/HTML/scss/_contact.scss */
.contact-info .media-body p {
  color: #8a8a8a;
}

/*=================== contact banner end ====================*/
/*=================== contact form start ====================*/
/* line 45, ../../Arafath/CL/December/235. Job board/HTML/scss/_contact.scss */
.contact-title {
  font-size: 27px;
  font-weight: 600;
  margin-bottom: 20px;
}

/* line 53, ../../Arafath/CL/December/235. Job board/HTML/scss/_contact.scss */
.form-contact label {
  font-size: 14px;
}

/* line 57, ../../Arafath/CL/December/235. Job board/HTML/scss/_contact.scss */
.form-contact .form-group {
  margin-bottom: 30px;
}

/* line 61, ../../Arafath/CL/December/235. Job board/HTML/scss/_contact.scss */
.form-contact .form-control {
  border: 1px solid #e5e6e9;
  border-radius: 0px;
  height: 48px;
  padding-left: 18px;
  font-size: 13px;
  background: transparent;
}

/* line 69, ../../Arafath/CL/December/235. Job board/HTML/scss/_contact.scss */
.form-contact .form-control:focus {
  outline: 0;
  box-shadow: none;
}

/* line 74, ../../Arafath/CL/December/235. Job board/HTML/scss/_contact.scss */
.form-contact .form-control::placeholder {
  font-weight: 300;
  color: #999999;
}

/* line 80, ../../Arafath/CL/December/235. Job board/HTML/scss/_contact.scss */
.form-contact textarea {
  border-radius: 0px;
  height: 100% !important;
}

/*=================== contact form end ====================*/
/* Contact Success and error Area css
============================================================================================ */
/* line 97, ../../Arafath/CL/December/235. Job board/HTML/scss/_contact.scss */
.modal-message .modal-dialog {
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) !important;
  margin: 0px;
  max-width: 500px;
  width: 100%;
}

/* line 106, ../../Arafath/CL/December/235. Job board/HTML/scss/_contact.scss */
.modal-message .modal-dialog .modal-content .modal-header {
  text-align: center;
  display: block;
  border-bottom: none;
  padding-top: 50px;
  padding-bottom: 50px;
}

/* line 112, ../../Arafath/CL/December/235. Job board/HTML/scss/_contact.scss */
.modal-message .modal-dialog .modal-content .modal-header .close {
  position: absolute;
  right: -15px;
  top: -15px;
  padding: 0px;
  color: #fff;
  opacity: 1;
  cursor: pointer;
}

/* line 121, ../../Arafath/CL/December/235. Job board/HTML/scss/_contact.scss */
.modal-message .modal-dialog .modal-content .modal-header h2 {
  display: block;
  text-align: center;
  padding-bottom: 10px;
}

/* line 126, ../../Arafath/CL/December/235. Job board/HTML/scss/_contact.scss */
.modal-message .modal-dialog .modal-content .modal-header p {
  display: block;
}

/* line 133, ../../Arafath/CL/December/235. Job board/HTML/scss/_contact.scss */
.contact-section {
  padding: 130px 0 100px;
}

@media (max-width: 991px) {
  /* line 133, ../../Arafath/CL/December/235. Job board/HTML/scss/_contact.scss */
  .contact-section {
    padding: 70px 0 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 133, ../../Arafath/CL/December/235. Job board/HTML/scss/_contact.scss */
  .contact-section {
    padding: 80px 0 50px;
  }
}

/* line 141, ../../Arafath/CL/December/235. Job board/HTML/scss/_contact.scss */
.contact-section .btn_2 {
  background-color: #191d34;
  padding: 18px 60px;
  border-radius: 50px;
  margin-top: 0;
}

/* line 146, ../../Arafath/CL/December/235. Job board/HTML/scss/_contact.scss */
.contact-section .btn_2:hover {
  background-color: #ff5e13;
}

/* line 75, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.sample-text-area {
  background: #fff;
  padding: 100px 0 70px 0;
}

/* line 80, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.text-heading {
  margin-bottom: 30px;
  font-size: 24px;
}

/* line 85, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
b,
sup,
sub,
u,
del {
  color: #ff5e13;
}

/* line 93, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
h1 {
  font-size: 36px;
}

/* line 97, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
h2 {
  font-size: 30px;
}

/* line 101, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
h3 {
  font-size: 24px;
}

/* line 105, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
h4 {
  font-size: 18px;
}

/* line 109, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
h5 {
  font-size: 16px;
}

/* line 113, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
h6 {
  font-size: 14px;
}

/* line 117, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2em;
}

/* line 127, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.typography h1,
.typography h2,
.typography h3,
.typography h4,
.typography h5,
.typography h6 {
  color: #828bb2;
}

/* line 137, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.button-area {
  background: #fff;
}

/* line 138, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.button-area .border-top-generic {
  padding: 70px 15px;
  border-top: 1px dotted #eee;
}

/* line 146, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.button-group-area .genric-btn {
  margin-right: 10px;
  margin-top: 10px;
}

/* line 149, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.button-group-area .genric-btn:last-child {
  margin-right: 0;
}

/* line 155, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn {
  display: inline-block;
  outline: none;
  line-height: 40px;
  padding: 0 30px;
  font-size: .8em;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

/* line 166, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn:focus {
  outline: none;
}

/* line 169, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.e-large {
  padding: 0 40px;
  line-height: 50px;
}

/* line 173, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.large {
  line-height: 45px;
}

/* line 176, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.medium {
  line-height: 30px;
}

/* line 179, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.small {
  line-height: 25px;
}

/* line 182, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.radius {
  border-radius: 3px;
}

/* line 185, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.circle {
  border-radius: 20px;
}

/* line 188, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.arrow {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 195, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.arrow span {
  margin-left: 10px;
}

/* line 199, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.default {
  color: #415094;
  background: #f9f9ff;
  border: 1px solid transparent;
}

/* line 203, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.default:hover {
  border: 1px solid #f9f9ff;
  background: #fff;
}

/* line 208, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.default-border {
  border: 1px solid #f9f9ff;
  background: #fff;
}

/* line 211, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.default-border:hover {
  color: #415094;
  background: #f9f9ff;
  border: 1px solid transparent;
}

/* line 217, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.primary {
  color: #fff;
  background: #ff5e13;
  border: 1px solid transparent;
}

/* line 221, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.primary:hover {
  color: #ff5e13;
  border: 1px solid #ff5e13;
  background: #fff;
}

/* line 227, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.primary-border {
  color: #ff5e13;
  border: 1px solid #ff5e13;
  background: #fff;
}

/* line 231, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.primary-border:hover {
  color: #fff;
  background: #ff5e13;
  border: 1px solid transparent;
}

/* line 237, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.success {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}

/* line 241, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.success:hover {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}

/* line 247, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.success-border {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}

/* line 251, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.success-border:hover {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}

/* line 257, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.info {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}

/* line 261, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.info:hover {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}

/* line 267, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.info-border {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}

/* line 271, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.info-border:hover {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}

/* line 277, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.warning {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}

/* line 281, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.warning:hover {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}

/* line 287, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.warning-border {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}

/* line 291, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.warning-border:hover {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}

/* line 297, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.danger {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}

/* line 301, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.danger:hover {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}

/* line 307, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.danger-border {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}

/* line 311, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.danger-border:hover {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}

/* line 317, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.link {
  color: #415094;
  background: #f9f9ff;
  text-decoration: underline;
  border: 1px solid transparent;
}

/* line 322, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.link:hover {
  color: #415094;
  border: 1px solid #f9f9ff;
  background: #fff;
}

/* line 328, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.link-border {
  color: #415094;
  border: 1px solid #f9f9ff;
  background: #fff;
  text-decoration: underline;
}

/* line 333, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.link-border:hover {
  color: #415094;
  background: #f9f9ff;
  border: 1px solid transparent;
}

/* line 339, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.genric-btn.disable {
  color: #222222, 0.3;
  background: #f9f9ff;
  border: 1px solid transparent;
  cursor: not-allowed;
}

/* line 347, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.generic-blockquote {
  padding: 30px 50px 30px 30px;
  background: #f9f9ff;
  border-left: 2px solid #ff5e13;
}

/* line 353, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.progress-table-wrap {
  overflow-x: scroll;
}

/* line 357, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.progress-table {
  background: #f9f9ff;
  padding: 15px 0px 30px 0px;
  min-width: 800px;
}

/* line 361, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.progress-table .serial {
  width: 11.83%;
  padding-left: 30px;
}

/* line 365, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.progress-table .country {
  width: 28.07%;
}

/* line 368, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.progress-table .visit {
  width: 19.74%;
}

/* line 371, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.progress-table .percentage {
  width: 40.36%;
  padding-right: 50px;
}

/* line 375, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.progress-table .table-head {
  display: flex;
}

/* line 377, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.progress-table .table-head .serial,
.progress-table .table-head .country,
.progress-table .table-head .visit,
.progress-table .table-head .percentage {
  color: #415094;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: 500;
}

/* line 387, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.progress-table .table-row {
  padding: 15px 0;
  border-top: 1px solid #edf3fd;
  display: flex;
}

/* line 391, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.progress-table .table-row .serial,
.progress-table .table-row .country,
.progress-table .table-row .visit,
.progress-table .table-row .percentage {
  display: flex;
  align-items: center;
}

/* line 399, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.progress-table .table-row .country img {
  margin-right: 15px;
}

/* line 404, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.progress-table .table-row .percentage .progress {
  width: 80%;
  border-radius: 0px;
  background: transparent;
}

/* line 408, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar {
  height: 5px;
  line-height: 5px;
}

/* line 411, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-1 {
  background-color: #6382e6;
}

/* line 414, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-2 {
  background-color: #e66686;
}

/* line 417, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-3 {
  background-color: #f09359;
}

/* line 420, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-4 {
  background-color: #73fbaf;
}

/* line 423, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-5 {
  background-color: #73fbaf;
}

/* line 426, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-6 {
  background-color: #6382e6;
}

/* line 429, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-7 {
  background-color: #a367e7;
}

/* line 432, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-8 {
  background-color: #e66686;
}

/* line 441, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.single-gallery-image {
  margin-top: 30px;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  height: 200px;
}

/* line 449, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.list-style {
  width: 14px;
  height: 14px;
}

/* line 455, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.unordered-list li {
  position: relative;
  padding-left: 30px;
  line-height: 1.82em !important;
}

/* line 459, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.unordered-list li:before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border: 3px solid #ff5e13;
  background: #fff;
  top: 4px;
  left: 0;
  border-radius: 50%;
}

/* line 473, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.ordered-list {
  margin-left: 30px;
}

/* line 475, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.ordered-list li {
  list-style-type: decimal-leading-zero;
  color: #ff5e13;
  font-weight: 500;
  line-height: 1.82em !important;
}

/* line 480, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.ordered-list li span {
  font-weight: 300;
  color: #828bb2;
}

/* line 488, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.ordered-list-alpha li {
  margin-left: 30px;
  list-style-type: lower-alpha;
  color: #ff5e13;
  font-weight: 500;
  line-height: 1.82em !important;
}

/* line 494, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.ordered-list-alpha li span {
  font-weight: 300;
  color: #828bb2;
}

/* line 502, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.ordered-list-roman li {
  margin-left: 30px;
  list-style-type: lower-roman;
  color: #ff5e13;
  font-weight: 500;
  line-height: 1.82em !important;
}

/* line 508, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.ordered-list-roman li span {
  font-weight: 300;
  color: #828bb2;
}

/* line 515, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.single-input {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

/* line 523, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.single-input:focus {
  outline: none;
}

/* line 528, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.input-group-icon {
  position: relative;
}

/* line 530, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.input-group-icon .icon {
  position: absolute;
  left: 20px;
  top: 0;
  line-height: 40px;
  z-index: 3;
}

/* line 535, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.input-group-icon .icon i {
  color: #797979;
}

/* line 540, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.input-group-icon .single-input {
  padding-left: 45px;
}

/* line 545, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.single-textarea {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
  height: 100px;
  resize: none;
}

/* line 555, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.single-textarea:focus {
  outline: none;
}

/* line 560, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.single-input-primary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

/* line 568, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.single-input-primary:focus {
  outline: none;
  border: 1px solid #ff5e13;
}

/* line 574, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.single-input-accent {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

/* line 582, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.single-input-accent:focus {
  outline: none;
  border: 1px solid #eb6b55;
}

/* line 588, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.single-input-secondary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

/* line 596, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.single-input-secondary:focus {
  outline: none;
  border: 1px solid #f09359;
}

/* line 602, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.default-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 609, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.default-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

/* line 619, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.default-switch input + label {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ff5e13;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

/* line 632, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.default-switch input:checked + label {
  left: 19px;
}

/* line 639, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.primary-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 646, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.primary-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 655, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.primary-switch input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

/* line 663, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.primary-switch input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

/* line 677, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.primary-switch input + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

/* line 693, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.primary-switch input:checked + label:after {
  left: 19px;
}

/* line 696, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.primary-switch input:checked + label:before {
  background: #ff5e13;
}

/* line 704, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.confirm-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 711, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.confirm-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 720, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.confirm-switch input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

/* line 728, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.confirm-switch input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
}

/* line 742, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.confirm-switch input + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

/* line 758, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.confirm-switch input:checked + label:after {
  left: 19px;
}

/* line 761, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.confirm-switch input:checked + label:before {
  background: #4cd3e3;
}

/* line 769, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.primary-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 776, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.primary-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 785, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.primary-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 798, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.primary-checkbox input:checked + label {
  border: none;
}

/* line 806, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.confirm-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 813, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.confirm-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 822, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.confirm-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 835, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.confirm-checkbox input:checked + label {
  border: none;
}

/* line 843, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.disabled-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 850, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.disabled-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 859, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.disabled-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 871, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.disabled-checkbox input:disabled {
  cursor: not-allowed;
  z-index: 3;
}

/* line 876, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.disabled-checkbox input:checked + label {
  border: none;
}

/* line 884, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.primary-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 891, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.primary-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 900, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.primary-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 913, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.primary-radio input:checked + label {
  border: none;
}

/* line 921, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.confirm-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 928, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.confirm-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 937, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.confirm-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 950, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.confirm-radio input:checked + label {
  border: none;
}

/* line 958, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.disabled-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 965, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.disabled-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 974, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.disabled-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 986, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.disabled-radio input:disabled {
  cursor: not-allowed;
  z-index: 3;
}

/* line 991, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.disabled-radio input:checked + label {
  border: none;
}

/* line 999, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.default-select {
  height: 40px;
}

/* line 1001, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.default-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #f9f9ff;
  padding-left: 20px;
  padding-right: 40px;
}

/* line 1008, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.default-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}

/* line 1015, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.default-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 20px;
}

/* line 1022, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.default-select .nice-select .list .option.selected {
  color: #ff5e13;
  background: transparent;
}

/* line 1026, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.default-select .nice-select .list .option:hover {
  color: #ff5e13;
  background: transparent;
}

/* line 1033, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.default-select .current {
  margin-right: 50px;
  font-weight: 300;
}

/* line 1037, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.default-select .nice-select::after {
  right: 20px;
}

/* line 1042, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.form-select {
  height: 40px;
  width: 100%;
}

/* line 1045, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.form-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #f9f9ff;
  padding-left: 45px;
  padding-right: 40px;
  width: 100%;
}

/* line 1053, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.form-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}

/* line 1060, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.form-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 45px;
}

/* line 1067, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.form-select .nice-select .list .option.selected {
  color: #ff5e13;
  background: transparent;
}

/* line 1071, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.form-select .nice-select .list .option:hover {
  color: #ff5e13;
  background: transparent;
}

/* line 1078, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.form-select .current {
  margin-right: 50px;
  font-weight: 300;
}

/* line 1082, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.form-select .nice-select::after {
  right: 20px;
}

/* line 1086, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.mt-10 {
  margin-top: 10px;
}

/* line 1089, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.section-top-border {
  padding: 50px 0;
  border-top: 1px dotted #eee;
}

/* line 1093, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.mb-30 {
  margin-bottom: 30px;
}

/* line 1096, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.mt-30 {
  margin-top: 30px;
}

/* line 1099, ../../Arafath/CL/December/235. Job board/HTML/scss/_elements.scss */
.switch-wrap {
  margin-bottom: 10px;
}

/* Start Blog Area css
============================================================================================ */
/* line 5, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.latest-blog-area .area-heading {
  margin-bottom: 70px;
}

/* line 10, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_area a {
  color: #666666 !important;
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 14, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_area a:hover, .blog_area a :hover {
  background: -webkit-linear-gradient(131deg, #00D363 0%, #00D363 99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 24, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-blog {
  overflow: hidden;
  margin-bottom: 30px;
}

/* line 28, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-blog:hover {
  box-shadow: 0px 10px 20px 0px rgba(42, 34, 123, 0.1);
}

/* line 32, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-blog .thumb {
  overflow: hidden;
  position: relative;
}

/* line 36, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-blog .thumb:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 49, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-blog h4 {
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 34px;
  margin-bottom: 25px;
}

/* line 56, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-blog a {
  font-size: 20px;
  font-weight: 600;
}

/* line 66, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-blog .date {
  color: #666666;
  text-align: left;
  display: inline-block;
  font-size: 13px;
  font-weight: 300;
}

/* line 74, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-blog .tag {
  text-align: left;
  display: inline-block;
  float: left;
  font-size: 13px;
  font-weight: 300;
  margin-right: 22px;
  position: relative;
}

/* line 84, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-blog .tag:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 10px;
  background: #acacac;
  right: -12px;
  top: 7px;
}

@media (max-width: 1199px) {
  /* line 74, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
  .single-blog .tag {
    margin-right: 8px;
  }
  /* line 98, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
  .single-blog .tag:after {
    display: none;
  }
}

/* line 104, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-blog .likes {
  margin-right: 16px;
}

@media (max-width: 800px) {
  /* line 24, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
  .single-blog {
    margin-bottom: 30px;
  }
}

/* line 112, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-blog .single-blog-content {
  padding: 30px;
}

/* line 116, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-blog .single-blog-content .meta-bottom p {
  font-size: 13px;
  font-weight: 300;
}

/* line 121, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-blog .single-blog-content .meta-bottom i {
  color: #fdcb9e;
  font-size: 13px;
  margin-right: 7px;
}

@media (max-width: 1199px) {
  /* line 112, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
  .single-blog .single-blog-content {
    padding: 15px;
  }
}

/* line 135, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-blog:hover .thumb:after {
  opacity: .7;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

@media (max-width: 1199px) {
  /* line 143, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
  .single-blog h4 {
    transition: all 300ms linear 0s;
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 14px;
    margin-bottom: 12px;
  }
  /* line 149, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
  .single-blog h4 a {
    font-size: 18px;
  }
}

/* line 157, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.full_image.single-blog {
  position: relative;
}

/* line 160, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.full_image.single-blog .single-blog-content {
  position: absolute;
  left: 35px;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

@media (min-width: 992px) {
  /* line 160, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
  .full_image.single-blog .single-blog-content {
    bottom: 100px;
  }
}

/* line 179, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.full_image.single-blog h4 {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-bottom: none;
  padding-bottom: 5px;
}

/* line 185, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.full_image.single-blog a {
  font-size: 20px;
  font-weight: 600;
}

/* line 195, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.full_image.single-blog .date {
  color: #fff;
}

/* line 200, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.full_image.single-blog:hover .single-blog-content {
  opacity: 1;
  visibility: visible;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* End Blog Area css
============================================================================================ */
/* Latest Blog Area css
============================================================================================ */
/* line 224, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.l_blog_item .l_blog_text .date {
  margin-top: 24px;
  margin-bottom: 15px;
}

/* line 228, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.l_blog_item .l_blog_text .date a {
  font-size: 12px;
}

/* line 234, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.l_blog_item .l_blog_text h4 {
  font-size: 18px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 0px;
  padding-bottom: 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 247, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.l_blog_item .l_blog_text p {
  margin-bottom: 0px;
  padding-top: 20px;
}

/* End Latest Blog Area css
============================================================================================ */
/* Causes Area css
============================================================================================ */
/* line 263, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.causes_slider .owl-dots {
  text-align: center;
  margin-top: 80px;
}

/* line 267, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.causes_slider .owl-dots .owl-dot {
  height: 14px;
  width: 14px;
  background: #eeeeee;
  display: inline-block;
  margin-right: 7px;
}

/* line 274, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.causes_slider .owl-dots .owl-dot:last-child {
  margin-right: 0px;
}

/* line 285, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.causes_item {
  background: #fff;
}

/* line 288, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.causes_item .causes_img {
  position: relative;
}

/* line 291, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.causes_item .causes_img .c_parcent {
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0px;
  height: 3px;
  background: rgba(255, 255, 255, 0.5);
}

/* line 299, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.causes_item .causes_img .c_parcent span {
  width: 70%;
  height: 3px;
  position: absolute;
  left: 0px;
  bottom: 0px;
}

/* line 307, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.causes_item .causes_img .c_parcent span:before {
  content: "75%";
  position: absolute;
  right: -10px;
  bottom: 0px;
  color: #fff;
  padding: 0px 5px;
}

/* line 320, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.causes_item .causes_text {
  padding: 30px 35px 40px 30px;
}

/* line 323, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.causes_item .causes_text h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  cursor: pointer;
}

/* line 336, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.causes_item .causes_text p {
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  margin-bottom: 0px;
}

/* line 346, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.causes_item .causes_bottom a {
  width: 50%;
  border: 1px solid;
  text-align: center;
  float: left;
  line-height: 50px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

/* line 358, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.causes_item .causes_bottom a + a {
  border-color: #eeeeee;
  background: #fff;
  font-size: 14px;
}

/* End Causes Area css
============================================================================================ */
/*================= latest_blog_area css =============*/
/* line 374, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.latest_blog_area {
  background: #f9f9ff;
}

/* line 378, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-recent-blog-post {
  margin-bottom: 30px;
}

/* line 381, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-recent-blog-post .thumb {
  overflow: hidden;
}

/* line 384, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-recent-blog-post .thumb img {
  transition: all 0.7s linear;
}

/* line 389, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-recent-blog-post .details {
  padding-top: 30px;
}

/* line 392, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-recent-blog-post .details .sec_h4 {
  line-height: 24px;
  padding: 10px 0px 13px;
  transition: all 0.3s linear;
}

/* line 403, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-recent-blog-post .date {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

/* line 410, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-recent-blog-post:hover img {
  transform: scale(1.23) rotate(10deg);
}

/* line 417, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.tags .tag_btn {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  border: 1px solid #eeeeee;
  display: inline-block;
  padding: 1px 18px;
  text-align: center;
}

/* line 431, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.tags .tag_btn + .tag_btn {
  margin-left: 2px;
}

/*========= blog_categorie_area css ===========*/
/* line 438, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_categorie_area {
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (min-width: 900px) {
  /* line 438, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
  .blog_categorie_area {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 1100px) {
  /* line 438, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
  .blog_categorie_area {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

/* line 454, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.categories_post {
  position: relative;
  text-align: center;
  cursor: pointer;
}

/* line 459, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.categories_post img {
  max-width: 100%;
}

/* line 463, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.categories_post .categories_details {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: rgba(34, 34, 34, 0.75);
  color: #fff;
  transition: all 0.3s linear;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 476, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.categories_post .categories_details h5 {
  margin-bottom: 0px;
  font-size: 18px;
  line-height: 26px;
  text-transform: uppercase;
  color: #fff;
  position: relative;
}

/* line 494, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.categories_post .categories_details p {
  font-weight: 300;
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 0px;
}

/* line 501, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.categories_post .categories_details .border_line {
  margin: 10px 0px;
  background: #fff;
  width: 100%;
  height: 1px;
}

/* line 510, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.categories_post:hover .categories_details {
  background: rgba(222, 99, 32, 0.85);
}

/*============ blog_left_sidebar css ==============*/
/* line 525, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_item {
  margin-bottom: 50px;
}

/* line 529, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_details {
  padding: 30px 0 20px 10px;
  box-shadow: 0px 10px 20px 0px rgba(221, 221, 221, 0.3);
}

@media (min-width: 768px) {
  /* line 529, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
  .blog_details {
    padding: 60px 30px 35px 35px;
  }
}

/* line 537, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_details p {
  margin-bottom: 30px;
  font-family: "Roboto", sans-serif;
}

/* line 542, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_details a {
  color: #ff8b23;
}

/* line 545, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_details a:hover {
  color: #00D363;
}

/* line 550, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_details h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}

@media (min-width: 768px) {
  /* line 550, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
  .blog_details h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }
}

/* line 564, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog-info-link li {
  float: left;
  font-size: 14px;
}

/* line 568, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog-info-link li a {
  color: #999999;
}

/* line 572, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog-info-link li i,
.blog-info-link li span {
  font-size: 13px;
  margin-right: 5px;
}

/* line 578, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog-info-link li::after {
  content: "|";
  padding-left: 10px;
  padding-right: 10px;
}

/* line 584, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog-info-link li:last-child::after {
  display: none;
}

/* line 589, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog-info-link::after {
  content: "";
  display: block;
  clear: both;
  display: table;
}

/* line 597, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_item_img {
  position: relative;
}

/* line 600, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_item_img .blog_item_date {
  position: absolute;
  bottom: -10px;
  left: 10px;
  display: block;
  color: #fff;
  background-color: #00D363;
  padding: 8px 15px;
  border-radius: 5px;
}

@media (min-width: 768px) {
  /* line 600, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
  .blog_item_img .blog_item_date {
    bottom: -20px;
    left: 40px;
    padding: 13px 30px;
  }
}

/* line 616, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_item_img .blog_item_date h3 {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
  line-height: 1.2;
}

@media (min-width: 768px) {
  /* line 616, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
  .blog_item_img .blog_item_date h3 {
    font-size: 30px;
  }
}

/* line 628, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_item_img .blog_item_date p {
  font-size: 18px;
  margin-bottom: 0;
  color: #fff;
}

@media (min-width: 768px) {
  /* line 628, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
  .blog_item_img .blog_item_date p {
    font-size: 18px;
  }
}

/* line 648, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .widget_title {
  font-size: 20px;
  margin-bottom: 40px;
}

/* line 653, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .widget_title::after {
  content: "";
  display: block;
  padding-top: 15px;
  border-bottom: 1px solid #f0e9ff;
}

/* line 661, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .single_sidebar_widget {
  background: #fbf9ff;
  padding: 30px;
  margin-bottom: 30px;
}

/* line 665, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .single_sidebar_widget .btn_1 {
  margin-top: 0px;
}

/* line 673, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .search_widget .form-control {
  height: 50px;
  border-color: #f0e9ff;
  font-size: 13px;
  color: #999999;
  padding-left: 20px;
  border-radius: 0;
  border-right: 0;
}

/* line 682, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .search_widget .form-control::placeholder {
  color: #999999;
}

/* line 686, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .search_widget .form-control:focus {
  border-color: #f0e9ff;
  outline: 0;
  box-shadow: none;
}

/* line 695, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .search_widget .input-group button {
  background: #fff;
  border-left: 0;
  border: 1px solid #f0e9ff;
  padding: 4px 15px;
  border-left: 0;
}

/* line 702, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .search_widget .input-group button i,
.blog_right_sidebar .search_widget .input-group button span {
  font-size: 14px;
  color: #999999;
}

/* line 714, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .newsletter_widget .form-control {
  height: 50px;
  border-color: #f0e9ff;
  font-size: 13px;
  color: #999999;
  padding-left: 20px;
  border-radius: 0;
}

/* line 723, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .newsletter_widget .form-control::placeholder {
  color: #999999;
}

/* line 727, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .newsletter_widget .form-control:focus {
  border-color: #f0e9ff;
  outline: 0;
  box-shadow: none;
}

/* line 736, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .newsletter_widget .input-group button {
  background: #fff;
  border-left: 0;
  border: 1px solid #f0e9ff;
  padding: 4px 15px;
  border-left: 0;
}

/* line 743, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .newsletter_widget .input-group button i,
.blog_right_sidebar .newsletter_widget .input-group button span {
  font-size: 14px;
  color: #999999;
}

/* line 756, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .post_category_widget .cat-list li {
  border-bottom: 1px solid #f0e9ff;
  transition: all 0.3s ease 0s;
  padding-bottom: 12px;
}

/* line 761, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .post_category_widget .cat-list li:last-child {
  border-bottom: 0;
}

/* line 765, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .post_category_widget .cat-list li a {
  font-size: 14px;
  line-height: 20px;
  color: #888888;
}

/* line 770, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .post_category_widget .cat-list li a p {
  margin-bottom: 0px;
}

/* line 775, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .post_category_widget .cat-list li + li {
  padding-top: 15px;
}

/* line 792, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .popular_post_widget .post_item .media-body {
  justify-content: center;
  align-self: center;
  padding-left: 20px;
}

/* line 797, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .popular_post_widget .post_item .media-body h3 {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 6px;
  transition: all 0.3s linear;
}

/* line 808, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .popular_post_widget .post_item .media-body a:hover {
  color: #fff;
}

/* line 814, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .popular_post_widget .post_item .media-body p {
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 0px;
}

/* line 821, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .popular_post_widget .post_item + .post_item {
  margin-top: 20px;
}

/* line 829, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .tag_cloud_widget ul li {
  display: inline-block;
}

/* line 832, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .tag_cloud_widget ul li a {
  display: inline-block;
  border: 1px solid #eeeeee;
  background: #fff;
  padding: 4px 20px;
  margin-bottom: 8px;
  margin-right: 3px;
  transition: all 0.3s ease 0s;
  color: #888888;
  font-size: 13px;
}

/* line 843, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .tag_cloud_widget ul li a:hover {
  background: #00D363;
  color: #fff !important;
  -webkit-text-fill-color: #fff;
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 858, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .instagram_feeds .instagram_row {
  display: flex;
  margin-right: -6px;
  margin-left: -6px;
}

/* line 864, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .instagram_feeds .instagram_row li {
  width: 33.33%;
  float: left;
  padding-right: 6px;
  padding-left: 6px;
  margin-bottom: 15px;
}

/* line 964, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog_right_sidebar .br {
  width: 100%;
  height: 1px;
  background: #eeeeee;
  margin: 30px 0px;
}

/* line 985, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog-pagination {
  margin-top: 80px;
}

/* line 989, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog-pagination .page-link {
  font-size: 14px;
  position: relative;
  display: block;
  padding: 0;
  text-align: center;
  margin-left: -1px;
  line-height: 45px;
  width: 45px;
  height: 45px;
  border-radius: 0 !important;
  color: #8a8a8a;
  border: 1px solid #f0e9ff;
  margin-right: 10px;
}

/* line 1006, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog-pagination .page-link i,
.blog-pagination .page-link span {
  font-size: 13px;
}

/* line 1018, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog-pagination .page-item.active .page-link {
  background-color: #fbf9ff;
  border-color: #f0e9ff;
  color: #888888;
}

/* line 1025, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.blog-pagination .page-item:last-child .page-link {
  margin-right: 0;
}

/*============ Start Blog Single Styles  =============*/
/* line 1050, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .blog_details {
  box-shadow: none;
  padding: 0;
}

/* line 1055, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .social-links {
  padding-top: 10px;
}

/* line 1058, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .social-links li {
  display: inline-block;
  margin-bottom: 10px;
}

/* line 1062, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .social-links li a {
  color: #cccccc;
  padding: 7px;
  font-size: 14px;
  transition: all 0.2s linear;
}

/* line 1075, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .blog_details {
  padding-top: 26px;
}

/* line 1078, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .blog_details p {
  margin-bottom: 20px;
  font-size: 15px;
}

/* line 1088, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .quote-wrapper {
  background: rgba(130, 139, 178, 0.1);
  padding: 15px;
  line-height: 1.733;
  color: #888888;
  font-style: italic;
  margin-top: 25px;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  /* line 1088, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
  .single-post-area .quote-wrapper {
    padding: 30px;
  }
}

/* line 1102, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .quotes {
  background: #fff;
  padding: 15px 15px 15px 20px;
  border-left: 2px solid;
  font-family: "Roboto", sans-serif;
}

@media (min-width: 768px) {
  /* line 1102, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
  .single-post-area .quotes {
    padding: 25px 25px 25px 30px;
  }
}

/* line 1112, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .arrow {
  position: absolute;
}

/* line 1115, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .arrow .lnr {
  font-size: 20px;
  font-weight: 600;
}

/* line 1122, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .thumb .overlay-bg {
  background: rgba(0, 0, 0, 0.8);
}

/* line 1127, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .navigation-top {
  padding-top: 15px;
  border-top: 1px solid #f0e9ff;
}

/* line 1131, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .navigation-top p {
  margin-bottom: 0;
}

/* line 1135, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .navigation-top .like-info {
  font-size: 14px;
}

/* line 1138, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .navigation-top .like-info i,
.single-post-area .navigation-top .like-info span {
  font-size: 16px;
  margin-right: 5px;
}

/* line 1145, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .navigation-top .comment-count {
  font-size: 14px;
}

/* line 1148, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .navigation-top .comment-count i,
.single-post-area .navigation-top .comment-count span {
  font-size: 16px;
  margin-right: 5px;
}

/* line 1157, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .navigation-top .social-icons li {
  display: inline-block;
  margin-right: 15px;
}

/* line 1161, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .navigation-top .social-icons li:last-child {
  margin: 0;
}

/* line 1165, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .navigation-top .social-icons li i,
.single-post-area .navigation-top .social-icons li span {
  font-size: 14px;
  color: #999999;
}

/* line 1183, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .blog-author {
  padding: 40px 30px;
  background: #fbf9ff;
  margin-top: 50px;
}

@media (max-width: 600px) {
  /* line 1183, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
  .single-post-area .blog-author {
    padding: 20px 8px;
  }
}

/* line 1192, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .blog-author img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-right: 30px;
}

@media (max-width: 600px) {
  /* line 1192, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
  .single-post-area .blog-author img {
    margin-right: 15px;
    width: 45px;
    height: 45px;
  }
}

/* line 1205, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .blog-author a {
  display: inline-block;
}

/* line 1209, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .blog-author a:hover {
  color: #00D363;
}

/* line 1214, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .blog-author p {
  margin-bottom: 0;
  font-size: 15px;
}

/* line 1219, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .blog-author h4 {
  font-size: 16px;
}

/* line 1226, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .navigation-area {
  border-bottom: 1px solid #eee;
  padding-bottom: 30px;
  margin-top: 55px;
}

/* line 1231, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .navigation-area p {
  margin-bottom: 0px;
}

/* line 1235, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .navigation-area h4 {
  font-size: 18px;
  line-height: 25px;
}

/* line 1241, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .navigation-area .nav-left {
  text-align: left;
}

/* line 1244, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .navigation-area .nav-left .thumb {
  margin-right: 20px;
  background: #000;
}

/* line 1248, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .navigation-area .nav-left .thumb img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 1253, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .navigation-area .nav-left .lnr {
  margin-left: 20px;
  opacity: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 1260, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .navigation-area .nav-left:hover .lnr {
  opacity: 1;
}

/* line 1265, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .navigation-area .nav-left:hover .thumb img {
  opacity: .5;
}

@media (max-width: 767px) {
  /* line 1241, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
  .single-post-area .navigation-area .nav-left {
    margin-bottom: 30px;
  }
}

/* line 1276, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .navigation-area .nav-right {
  text-align: right;
}

/* line 1279, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .navigation-area .nav-right .thumb {
  margin-left: 20px;
  background: #000;
}

/* line 1283, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .navigation-area .nav-right .thumb img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 1288, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .navigation-area .nav-right .lnr {
  margin-right: 20px;
  opacity: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 1295, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .navigation-area .nav-right:hover .lnr {
  opacity: 1;
}

/* line 1300, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.single-post-area .navigation-area .nav-right:hover .thumb img {
  opacity: .5;
}

@media (max-width: 991px) {
  /* line 1308, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
  .single-post-area .sidebar-widgets {
    padding-bottom: 0px;
  }
}

/* line 1315, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.comments-area {
  background: transparent;
  border-top: 1px solid #eee;
  padding: 45px 0;
  margin-top: 50px;
}

@media (max-width: 414px) {
  /* line 1315, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
  .comments-area {
    padding: 50px 8px;
  }
}

/* line 1326, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.comments-area h4 {
  margin-bottom: 35px;
  font-size: 18px;
}

/* line 1333, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.comments-area h5 {
  font-size: 16px;
  margin-bottom: 0px;
}

/* line 1342, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.comments-area .comment-list {
  padding-bottom: 48px;
}

/* line 1345, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.comments-area .comment-list:last-child {
  padding-bottom: 0px;
}

/* line 1349, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.comments-area .comment-list.left-padding {
  padding-left: 25px;
}

@media (max-width: 413px) {
  /* line 1355, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
  .comments-area .comment-list .single-comment h5 {
    font-size: 12px;
  }
  /* line 1359, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
  .comments-area .comment-list .single-comment .date {
    font-size: 11px;
  }
  /* line 1363, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
  .comments-area .comment-list .single-comment .comment {
    font-size: 10px;
  }
}

/* line 1370, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.comments-area .thumb {
  margin-right: 20px;
}

/* line 1373, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.comments-area .thumb img {
  width: 70px;
  border-radius: 50%;
}

/* line 1379, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.comments-area .date {
  font-size: 14px;
  color: #999999;
  margin-bottom: 0;
  margin-left: 20px;
}

/* line 1386, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.comments-area .comment {
  margin-bottom: 10px;
  color: #777777;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
}

/* line 1393, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.comments-area .btn-reply {
  background-color: transparent;
  color: #888888;
  padding: 5px 18px;
  font-size: 14px;
  display: block;
  font-weight: 400;
}

/* line 1410, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.comment-form {
  border-top: 1px solid #eee;
  padding-top: 45px;
  margin-top: 50px;
  margin-bottom: 20px;
}

/* line 1418, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.comment-form .form-group {
  margin-bottom: 30px;
}

/* line 1422, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.comment-form h4 {
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 22px;
}

/* line 1430, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.comment-form .name {
  padding-left: 0px;
}

@media (max-width: 767px) {
  /* line 1430, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
  .comment-form .name {
    padding-right: 0px;
    margin-bottom: 1rem;
  }
}

/* line 1439, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.comment-form .email {
  padding-right: 0px;
}

@media (max-width: 991px) {
  /* line 1439, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
  .comment-form .email {
    padding-left: 0px;
  }
}

/* line 1447, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.comment-form .form-control {
  border: 1px solid #f0e9ff;
  border-radius: 5px;
  height: 48px;
  padding-left: 18px;
  font-size: 13px;
  background: transparent;
}

/* line 1455, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.comment-form .form-control:focus {
  outline: 0;
  box-shadow: none;
}

/* line 1460, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.comment-form .form-control::placeholder {
  font-weight: 300;
  color: #999999;
}

/* line 1465, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.comment-form .form-control::placeholder {
  color: #777777;
}

/* line 1470, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.comment-form textarea {
  padding-top: 18px;
  border-radius: 12px;
  height: 100% !important;
}

/* line 1476, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.comment-form ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 13px;
  color: #777;
}

/* line 1482, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.comment-form ::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 13px;
  color: #777;
}

/* line 1488, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.comment-form :-ms-input-placeholder {
  /* IE 10+ */
  font-size: 13px;
  color: #777;
}

/* line 1494, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog.scss */
.comment-form :-moz-placeholder {
  /* Firefox 18- */
  font-size: 13px;
  color: #777;
}

/*============ End Blog Single Styles  =============*/
/**************** blog part css start ****************/
/* line 2, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
.blog_part {
  margin-bottom: 140px;
}

@media (max-width: 576px) {
  /* line 2, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
  .blog_part {
    margin-bottom: 0px;
    padding: 0px 0px 70px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  /* line 2, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
  .blog_part {
    margin-bottom: 0px;
    padding: 0px 0px 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 2, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
  .blog_part {
    margin-bottom: 0px;
    padding: 0px 0px 70px;
  }
}

/* line 19, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
.blog_part .blog_right_sidebar .widget_title {
  font-size: 20px;
  margin-bottom: 40px;
  font-style: inherit !important;
}

@media (max-width: 576px) {
  /* line 24, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog {
    margin-bottom: 140px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  /* line 24, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog {
    margin-bottom: 140px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 24, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog {
    margin-bottom: 140px;
    margin-top: 20px;
  }
}

/* line 40, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
.blog_part .single-home-blog .card-img-top {
  border-radius: 0px;
}

/* line 43, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
.blog_part .single-home-blog .card {
  border: 0px solid transparent;
  border-radius: 0px;
  background-color: transparent;
  position: relative;
}

/* line 48, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
.blog_part .single-home-blog .card .card-body {
  padding: 25px 10px 29px 40px;
  background-color: #fff;
  position: absolute;
  left: 20px;
  bottom: -140px;
  box-shadow: -7.552px 9.326px 20px 0px rgba(1, 84, 85, 0.1);
  border-radius: 10px;
}

@media (max-width: 576px) {
  /* line 48, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card .card-body {
    padding: 15px;
    left: 10px;
    bottom: -140px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  /* line 48, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card .card-body {
    padding: 15px;
    left: 10px;
    bottom: -140px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 48, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card .card-body {
    padding: 20px;
  }
}

/* line 72, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
.blog_part .single-home-blog .card .card-body a {
  color: #00D363;
  text-transform: uppercase;
  -webkit-transition: 0.8s;
  transition: 0.8s;
}

/* line 76, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
.blog_part .single-home-blog .card .card-body a:hover {
  background: -webkit-linear-gradient(131deg, #ff7e5f 0%, #feb47b 99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* line 83, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
.blog_part .single-home-blog .card .dot {
  position: relative;
  padding-left: 20px;
}

/* line 86, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
.blog_part .single-home-blog .card .dot:after {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  top: 5px;
  left: 0;
  background-color: #00D363;
  border-radius: 50%;
}

/* line 97, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
.blog_part .single-home-blog .card span {
  color: #8a8a8a;
  margin-bottom: 10px;
  display: inline-block;
  margin-top: 10px;
}

@media (max-width: 576px) {
  /* line 97, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card span {
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  /* line 97, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card span {
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 97, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card span {
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 97, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card span {
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

/* line 119, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
.blog_part .single-home-blog .card h5 {
  font-weight: 600;
  line-height: 1.5;
  font-size: 20px;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  text-transform: capitalize;
  margin-bottom: 22px;
}

@media (max-width: 576px) {
  /* line 119, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card h5 {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  /* line 119, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card h5 {
    margin-bottom: 10px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 119, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card h5 {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 119, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card h5 {
    margin-bottom: 10px;
    font-size: 18px;
  }
}

/* line 140, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
.blog_part .single-home-blog .card h5:hover {
  -webkit-transition: 0.8s;
  transition: 0.8s;
  background: -webkit-linear-gradient(131deg, #feb47b 0%, #ff7e5f 99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: 1s;
}

/* line 150, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
.blog_part .single-home-blog .card ul li {
  display: inline-block;
  color: #8a8a8a;
  margin-right: 14px;
}

@media (max-width: 576px) {
  /* line 150, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card ul li {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  /* line 150, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card ul li {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 150, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card ul li {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 150, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
  .blog_part .single-home-blog .card ul li {
    margin-right: 10px;
  }
}

/* line 166, ../../Arafath/CL/December/235. Job board/HTML/scss/_blog_part.scss */
.blog_part .single-home-blog .card ul li span {
  margin-right: 10px;
  font-size: 12px;
}

/*# sourceMappingURL=style.css.map */